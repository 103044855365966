import { Link } from 'react-router-dom';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { HelpDrawerTpl } from 'components/Help/HelpDrawerTpl';
import { iRegionModel } from 'shared/deployment';
import { Typography } from 'antd';

const { Title, Text } = Typography;

interface LinkOwnDomainProps {
  region: iRegionModel;
}

export const LinkOwnDomain = (props: LinkOwnDomainProps) => {
  return (
    <HelpDrawerTpl title={`How to link own domain?`}>
      <Title level={3}> How to link own domain? </Title>
      <Text>
        To link your own domain, you need to create a <Text strong> CNAME </Text> record in your DNS settings and point it to the
        {<CodeLine>{props.region.autoURL}</CodeLine>}
      </Text>
      <Title level={3}> Can I link own domain? </Title>
      <Text>
        Yes, you can link your own domain to the application. Be sure if you add the load balancer url or IP in the&nbsp;
        <Link to={`/clusters/${props.region.id}/settings`}> cluster configuration </Link>.
      </Text>
    </HelpDrawerTpl>
  );
};
