import gql from 'graphql-tag';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from 'utils/common';
import { useAuthedQuery } from 'utils/qlAuth';
import { PermissionCheck } from 'components/Permissions/Permissions';
import { Button, Card, Col, Flex, Image, Input, Result, Row, Skeleton, Space, Tag, Typography } from 'antd';
import { AppstoreOutlined, CaretLeftFilled, UnorderedListOutlined } from '@ant-design/icons';
import { buttonBorder, floatingLeft, floatingRight, shadowStyle, spaceWidth, topMargin } from 'utils/styles';
import { NoClusters } from 'components/SharedComponents/NoPage/NoPages';
import { TipBottom, TipBottomRight, TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

const { Text } = Typography;
const { Search } = Input;

export const NewClusterCreation = () => {
  const permissionAllow = (
    <Link to={`/cluster/new`}>
      <Button type="primary" style={buttonBorder}>
        New Cluster
      </Button>
    </Link>
  );

  const permissionDenied = (
    <Button type="primary" disabled style={buttonBorder}>
      New Cluster
    </Button>
  );

  return (
    <Flex data-qa="ClustersList.tsx">
      <PermissionCheck permission={`new_cluster`} allow={permissionAllow} no={permissionDenied} />
    </Flex>
  );
};

export const ClustersList = () => {
  const [isCompactView, setIsCompactView] = useState(false);
  const [activeButton, setActiveButton] = useState<'list' | 'card'>('list');
  const [searchedClusters, setSearchedClusters] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { loading, error, data } = useAuthedQuery(gql`
    query RegionsController_getRegions {
      RegionsController_getRegions {
        id
        tenant
        name
        title
        kubernetesVersion
        cloudProvider
        isPublic
        configureStatus
        integrationsStatus
        allowToAddDeployments
        regionName
      }
    }
  `);

  const user = useUser();

  if (!user || loading || error) return <Skeleton active={true} loading={true} />;

  const clusters = [...(data?.RegionsController_getRegions || [])].sort((a, b) =>
    a.isPublic !== b.isPublic ? a.isPublic - b.isPublic : b.id !== a.id ? b.id - a.id : 0,
  );

  const toggleView = (view: 'list' | 'card') => {
    setIsCompactView(view === 'card');
    setActiveButton(view);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    const filteredClusters = clusters.filter(
      cluster => cluster.name.toLowerCase().includes(value.toLowerCase()) || cluster.id.toString().toLowerCase().includes(value.toLowerCase()),
    );
    setSearchedClusters(filteredClusters);
  };

  const renderClusters = () => {
    const clustersToRender = (searchQuery ? searchedClusters : clusters).sort((a, b) => a.id - b.id);

    const noData = () => {
      const noResults = (
        <Button type="primary" onClick={() => setSearchQuery('')} icon={<CaretLeftFilled />}>
          Back to Clusters
        </Button>
      );
      return (
        <Col span={24}>
          <Result status="404" subTitle="This Cluster does not exist... Please search for appropriate Cluster" extra={noResults} style={topMargin} />
        </Col>
      );
    };

    if (clustersToRender.length === 0) return noData();

    return clustersToRender.map(region => {
      const servicesImage =
        region.cloudProvider === 'aws'
          ? 'branding/logoAWS.svg'
          : region.cloudProvider === 'azure'
          ? 'branding/logoAzure.svg'
          : region.cloudProvider === 'gcp'
          ? 'branding/logoGCP.svg'
          : 'branding/logoKubernetes.svg';

      const linkTo =
        region.configureStatus !== 'configured' || region.integrationsStatus !== 'configured'
          ? `/cluster/new/${region.id}/basic`
          : `/clusters/${region.id}/overview`;

      const publicRegion = region.isPublic ? (
        <Tag color="grey" style={floatingLeft}>
          <TipBottom tip="Kubernetes Version"> Public </TipBottom>
        </Tag>
      ) : null;

      const tenantRegion =
        region.tenant === user.tenant ? (
          <Tag color="green" style={floatingLeft}>
            <TipBottom tip="Tenant"> Own </TipBottom>
          </Tag>
        ) : null;

      const regionNameRegion = region.regionName ? (
        <Tag color="blue" style={floatingLeft}>
          <TipBottom tip="Region"> {region.regionName} </TipBottom>
        </Tag>
      ) : null;

      const clusterTags = (
        <Tag color={region.kubernetesVersion ? 'green' : 'red'} style={floatingRight}>
          <TipBottom tip="Kubernetes Version"> Kubernetes {region.kubernetesVersion || 'N/A'} </TipBottom>
        </Tag>
      );

      const configureStatusRegionA = region.configureStatus === 'configured' && (
        <Tag color={region.allowToAddDeployments ? 'green' : 'red'} style={floatingRight}>
          <TipBottom tip="Application Status"> {region.allowToAddDeployments ? 'Ready' : 'Do not allow'} </TipBottom>
        </Tag>
      );

      const configureStatusRegionB = region.configureStatus !== 'configured' && (
        <Tag color={'magenta'} style={floatingRight}>
          <TipBottom tip="Configuration is not finished"> Not Ready </TipBottom>
        </Tag>
      );

      const cardExtra = (
        <Space direction="horizontal">
          <Tag> ID: {region.id} </Tag>
          <TipBottomRight tip={`Cloud Service Provider:  ${(region.cloudProvider || '').toUpperCase()}`}>
            <Image src={servicesImage} alt={region.cloudProvider} preview={false} height={20} style={shadowStyle} />
          </TipBottomRight>
        </Space>
      );

      return (
        <Col span={isCompactView ? 8 : 24} key={region.id} style={spaceWidth}>
          <Link to={linkTo}>
            <Card data-cy={region.id} size="small" type="inner" bordered={false} title={region.name} extra={cardExtra}>
              <Text strong>
                {publicRegion}
                {tenantRegion}
                {regionNameRegion}
                {clusterTags}
                {configureStatusRegionA}
                {configureStatusRegionB}
              </Text>
            </Card>
          </Link>
        </Col>
      );
    });
  };

  const searchViews = () => (
    <Row>
      <Col span={10}>
        <Search placeholder="Search using cluster name" enterButton allowClear onChange={e => handleSearch(e.target.value)} />
      </Col>
      <Col span={14}>
        <Flex justify="flex-end">
          <Space>
            <TipTop tip="List View">
              <Button icon={<UnorderedListOutlined />} onClick={() => toggleView('list')} type={activeButton === 'list' ? 'primary' : 'default'} />
            </TipTop>
            <TipTop tip="Grid View">
              <Button icon={<AppstoreOutlined />} onClick={() => toggleView('card')} type={activeButton === 'card' ? 'primary' : 'default'} />
            </TipTop>
            {NewClusterCreation()}
          </Space>
        </Flex>
      </Col>
    </Row>
  );

  const clusterData = () => (
    <>
      {searchViews()}
      <Text />
      <Row gutter={[16, 16]}> {renderClusters()} </Row>
      <Text />
    </>
  );

  const finalCluster = () => (
    <Space direction="vertical" style={spaceWidth}>
      {clusters.length > 0 ? clusterData() : <NoClusters />}
    </Space>
  );

  return finalCluster();
};
