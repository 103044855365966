import { ReactElement, useState } from 'react';
import { Skeleton, Tabs, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { iDeployment } from 'shared/deployment';
import { ProjectFiles } from 'components/Projects/FileEditor/ProjectFiles';
import { ProjectContainerStorage } from 'components/Projects/ProjectContainerStorage/ProjectContainerStorage';
import { ProjectPage } from 'components/Projects/ProjectPage';
import { GitLogs } from 'components/Projects/Settings/Git/gitLogs';
import { ProjectGitSettings } from 'components/Projects/Settings/Git/ProjectGitSettings';
import { ProjectDocs } from 'components/Projects/Settings/ProjectDocs';
import { ProjectEnvSettings } from 'components/Projects/Settings/ProjectEnvSettings';
import { ProjectGeneral } from 'components/Projects/Settings/ProjectGeneral';
import { ProjectVersions } from 'components/Projects/Settings/ProjectVersion';
import { ShareProject } from 'components/Projects/Settings/ShareProject';
import { isInEditMode, setEditMode } from 'utils/util';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { DeleteTab } from 'components/Deployments/Setting/General/settings/DeleteTab';

interface iConfigurationSettings {
  deployment: iDeployment;
  settingsTab: string;
}

const { TabPane } = Tabs;

export const ConfigurationSettings = (props: iConfigurationSettings): ReactElement => {
  const history = useHistory();
  const [isEditMode, setIsEditMode] = useState(isInEditMode(`dp`, props.deployment.id));
  const user = useUser();

  const project = props.deployment.ProjectModel;
  const deployment = props.deployment;
  let tabName = props.settingsTab;

  let defaultTab = tabName;
  const init = project.gitInitialized;
  const permit = userHasPermission(user, UserPermissions.ProjectsMange);

  if (!defaultTab) deployment.isReady === false ? (defaultTab = 'general') : (defaultTab = 'danger-zone');

  // tabName !== 'git' && tabName !== 'danger-zone' && !init
  //   ? (() => {
  //       history.push(`/app/${deployment.id}/configuration/git`);
  //       notification.error({ key: 'gitInitialized', message: 'Git configuration is required', description: 'Please configure git settings' });
  //       return null;
  //     })()
  //   : null;

  if (!tabName) {
    history.push(`/app/${deployment.id}/configuration/${defaultTab}`);
    return null;
  }

  if (!deployment || !user) return <Skeleton active={true} loading={true} />;

  const handleTabClick = (key: any) => history.push(`/app/${deployment.id}/configuration/settings/${key}`);

  const common: any = { permission: permit, edit: true };
  const gitTabs = [
    deployment.isReady === true ? { edit: false, key: 'danger-zone', tab: 'Danger zone', component: <DeleteTab id={deployment.id} /> } : null,
    { ...common, key: 'general', tab: 'General', component: <ProjectGeneral project={project} /> },
    { ...common, key: 'git', tab: 'GIT settings', component: <ProjectGitSettings project={project} /> },
    { ...common, key: 'git-audit', tab: 'GIT audit', component: <GitLogs project={project} /> },
    { ...common, key: 'docs', tab: 'Docs', component: <ProjectDocs project={project} /> },
    { ...common, key: 'share', tab: 'Share', component: <ShareProject project={project} /> },
    { ...common, key: 'env', tab: 'Env', component: <ProjectEnvSettings project={project} /> },
    { ...common, key: 'project-files', tab: 'Files', component: <ProjectFiles project={project} /> },
    { ...common, key: 'pull-secrets', tab: 'Pull secrets', component: <ProjectContainerStorage project={project} /> },
    { ...common, key: 'versions', tab: 'Versions', component: <ProjectVersions project={project} /> },
    { ...common, key: 'logs', tab: 'Activity', component: <ProjectPage project={project} /> },
    // { key: 'provisioner', tab: 'Provisioner', component: <ProvisionerConfiguration project={project} /> },
    // { key: 'permissions', tab: 'Permissions', component: <ClusterPermissions project={project} /> },
    // { key: 'danger-zone', tab: 'Danger Zone (to remove)', component: <DeleteProject project={project} /> },
  ]
    .filter(Boolean)
    .filter(v => v.permission !== false)
    .filter(v => v.edit === isEditMode || v.edit === false || deployment.isReady === false);

  console.log(`GitTabs - TabName: ${tabName} | DefaultTab: ${defaultTab} | Key: ${gitTabs.map(({ key }) => key)}`);

  const extraTab = () => {
    const switches = permit && deployment.isReady === true;
    const handleOnClick = val => {
      setIsEditMode(val);
      setEditMode(`dp`, props.deployment.id, val);
    };
    return switches && <Switch checkedChildren="Edit mode" unCheckedChildren="View mode" onClick={handleOnClick} checked={isEditMode} />;
  };

  return (
    <Tabs defaultActiveKey={tabName || defaultTab} onChange={handleTabClick} tabBarExtraContent={extraTab()}>
      {gitTabs.map(({ key, tab, component }) => (
        <TabPane key={key} tab={tab} disabled={!init}>
          {component}
        </TabPane>
      ))}
    </Tabs>
  );
};
