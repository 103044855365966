import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { ReactElement, ReactNode, ReactPortal, useState } from 'react';
import { projectService } from 'services/project.service';
import { CustomWidgets } from 'form-components';
import { iProjectModel } from 'shared/deployment';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { Alert, Button, Divider, Modal, Popconfirm, Progress, Space, Spin, Typography, notification } from 'antd';
import { bannerStyle, buttonBorder, modalStyle, spaceWidth } from 'utils/styles';

export interface iProjectOneServiceSettingsFormProps {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
  service: iCloudProjectServiceYamlSpecs;
}

interface iVType {
  type: string | number | boolean | ReactElement<string> | Iterable<ReactNode>;
  cmd?: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  message: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

const formSchema: any = {
  type: 'object',
  properties: {
    helmRepositoryName: { type: 'string', title: 'Helm chart repository name' },
    helmRepositoryURL: { type: 'string', title: 'Helm chart repository URL' },
    helmRepositoryVersion: { type: 'string', title: 'Helm chart version' },
    helmRepositoryChartName: { type: 'string', title: 'Helm chart name' },
    // helmChartName: {
    //   hidden: true,
    //   type: 'string',
    //   title: 'Chart path (offten the same as 'helm chart name')',
    //   description: `Path in project reposytory to the helm chart folder (please put all your helm charts to the folder "helm" in your project root directory)`,
    // },
  },
};

const formUI = {
  helmRepositoryName: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart repository name here' },
  helmRepositoryURL: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart repository URL here' },
  helmRepositoryVersion: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart version here' },
  helmRepositoryChartName: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart name here' },
  // helmChartName: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter chart name here' },
};

const { Text } = Typography;

async function showModalWithHelmCommandResult(resPromis: Promise<any>, title: string) {
  Modal.destroyAll();
  Modal.success({
    title: 'Loading...',
    content: <Progress percent={100} status="active" showInfo={false} />,
    icon: <Spin size="large" />,
    closable: false,
    onOk: () => resPromis,
  });

  try {
    const res = await resPromis;
    const { cmd: resCmd, error: resErr, logs: resLog, step1: stepPrimary, step2: stepSecondary } = res?.data || {};

    const resData = resCmd ? (
      <Text strong>
        {resCmd} <Divider />
      </Text>
    ) : (
      ''
    );

    const resError = resErr ? (
      <span style={{ color: '#BC4040' }}>
        {resErr} <Divider />
      </span>
    ) : null;

    const resLogs =
      resLog &&
      resLog.map(({ type, cmd, message }: iVType) => {
        const types = (
          <Text strong style={{ color: type === 'error' ? 'red' : 'green' }}>
            {type}
          </Text>
        );
        return (
          <Space direction="vertical">
            {cmd} {types}:{message} <Divider />
          </Space>
        );
      });

    Modal.destroyAll();
    const successModalContent = (
      <div style={modalStyle}>
        {[resData, resError, stepPrimary?.stdout, stepPrimary?.stderr, stepSecondary?.stdout, stepSecondary?.stderr, resLogs].map(
          (content, index) => content && <div key={index}> {content} </div>,
        )}
      </div>
    );
    res.error
      ? notification.error({ message: `Error: ${res.error || res?.data?.error}` })
      : Modal.success({ width: '800px', title: title, content: successModalContent });
    return res;
  } catch (error) {
    Modal.destroyAll();
    notification.error({ message: `Error: ${error}` });
  }
}

export const ProjectOneServiceSettingsHelmTab = (props: iProjectOneServiceSettingsFormProps) => {
  const projectId = props.project.id;
  const serviceName = props.serviceName;
  const tabType = props.tabType;
  const service = props.service;
  const [helmConfig, setHelmConfig] = useState(null);
  const serviceOrConfigs = helmConfig === null ? service : helmConfig;

  const handleSubmit = async e => {
    const data = e.formData;
    data.helmChartName = data.helmRepositoryChartName;
    const res = await projectService.setProjectServices(projectId, { tabType: tabType, name: data.name, data: data });
    res.error ? notification.error({ message: `Error: ${res.error}` }) : notification.success({ message: 'Ready' });
  };

  const handleOnChange = async e => {
    const data = e.formData;
    data.helmChartName = data.helmRepositoryChartName;
    setHelmConfig(data);
  };

  const tabConfigurations = () => {
    const {
      helmRepositoryName: repoName,
      helmRepositoryURL: repoURL,
      helmRepositoryVersion: repoVersion,
      helmRepositoryChartName: repoChart,
    } = serviceOrConfigs;
    const commonVersion = repoVersion && `--version ${repoVersion}`;

    const handleButton = async (action: 'add' | 'pull' | 'update') => {
      await handleSubmit({ formData: serviceOrConfigs });
      const methodMap = { add: projectService.serviceHelmAdd, pull: projectService.serviceHelmPull, update: projectService.serviceHelmUpdate };
      const message = `Helm repo ${action}...`;
      await showModalWithHelmCommandResult(methodMap[action](projectId, serviceName), message);
    };

    const helmAdd = () => handleButton('add');
    const helmPull = () => handleButton('pull');
    const helmUpdate = () => handleButton('update');

    const helmButtons = () => {
      const addData = (
        <CodeLine>
          helm repo add {repoName} {repoURL}
          {commonVersion}
        </CodeLine>
      );

      const pullData = (
        <Text>
          <ul>
            <li style={{ color: 'red' }}> This will remove current helm chart files, and is not a reversible action. </li>
            <li>
              helm pull {repoName} {repoChart}
            </li>
          </ul>
        </Text>
      );

      const updateData = (
        <ul>
          {['helm repo update', 'helm dependency update', 'helm dependency build'].map((command, index) => (
            <li key={index}> {command} </li>
          ))}
        </ul>
      );

      const helmActions = [
        { text: 'Add', helmAction: helmAdd, info: addData },
        { text: 'Pull', helmAction: helmPull, info: pullData, color: { danger: true } },
        { text: 'Update', helmAction: helmUpdate, info: updateData },
      ];

      return helmActions.map(({ text, helmAction, info, color }, index) => {
        const commonText = `Helm Repo ${text}`;
        const alertMessage = <Text strong> {commonText} </Text>;
        const alertAction = () => (
          <Popconfirm
            arrow
            placement="leftTop"
            title={`Do you want to run "helm ${text.toLowerCase()}" command?`}
            okText={`Initiate ${commonText}`}
            cancelText={'Cancel'}
            onConfirm={helmAction}
            description={info}
          >
            <Button {...color}> Start </Button>
          </Popconfirm>
        );
        return <Alert key={index} message={alertMessage} action={alertAction()} style={bannerStyle} />;
      });
    };

    const installInfo = () => {
      const alertMessage = (
        <>
          <Text strong> Helm Install: </Text> {repoChart} {repoName}/{repoChart} {commonVersion}
        </>
      );
      return <Alert message={alertMessage} style={bannerStyle} />;
    };

    return (
      <>
        {installInfo()}
        {helmButtons()}
      </>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Form
        widgets={CustomWidgets}
        formData={serviceOrConfigs}
        schema={formSchema}
        uiSchema={formUI}
        validator={validator}
        onChange={handleOnChange}
        onSubmit={handleSubmit}
        onError={e => console.log(`Error in form submit: ${e}`)}
      >
        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save
          </Button>
        </BottomButtons>
      </Form>
      {tabConfigurations()}
      <Text /> <Text /> <Text /> <Text /> <Text /> <Text />
    </Space>
  );
};
