import gql from 'graphql-tag';
import { Button, Modal, Select, Skeleton, Space, Tag, Typography } from 'antd';
import { ProjectServicesList } from 'components/Projects/Settings/ProjectCreateService';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { ReactElement, useState } from 'react';
import { iProjectModel } from 'shared/deployment';
import { useAuthedQuery } from 'utils/qlAuth';
import { cardTextStyle, spaceWidth } from 'utils/styles';
import { PlusOutlined } from '@ant-design/icons';
import { TipBottomLeft, TipRight } from 'components/SharedComponents/Tooltip/Tooltip';

const { Text } = Typography;
const display = { style: { display: 'none' } };

export const CopyServiceButton = (props: { project: iProjectModel; preSelectedProject?: iProjectModel }): ReactElement => {
  const [visible, setVisible] = useState(false);

  const projectId = props.project.id;
  const [activeProject, setProject] = useState(props.preSelectedProject || null);
  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList {
        ProjectController_getProjectList {
          id
          name
          title
          description
          logo
          tags
        }
      }
    `,
    {},
  );

  if (loading || error) return <Skeleton active={true} loading={true} />;

  const projectListOptions = (data?.ProjectController_getProjectList || []).map(({ description, tags, logo, title, id }) => {
    const tooltipTitle = (
      <Space direction="vertical">
        <Text style={cardTextStyle}> {description} </Text>
        {tags && tags.map(tag => <Tag color="green"> {tag} </Tag>)}
      </Space>
    );
    const tooltipLabel = (
      <TipRight tip={tooltipTitle}>
        <Space direction="horizontal">
          {logo && <LogoAvatar logo={logo} name={title} />}
          {title}
        </Space>
      </TipRight>
    );
    return { value: Number(id), label: tooltipLabel };
  });

  return (
    <>
      <TipBottomLeft tip="Click here to copy service from existing application">
        <Button type="default" icon={<PlusOutlined />} onClick={() => setVisible(true)}>
          Copy service from existing application
        </Button>
      </TipBottomLeft>
      <Modal
        open={visible}
        title={`Copy existing service`}
        width={800}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        cancelButtonProps={display}
        okButtonProps={display}
      >
        <Select placeholder="Choose the Project Here" onChange={setProject} options={projectListOptions} style={spaceWidth} />
        {activeProject && (
          <ProjectServicesList onFinish={() => setVisible(false)} srcProjectId={Number(activeProject)} drcProjectId={Number(projectId)} />
        )}
      </Modal>
    </>
  );
};
