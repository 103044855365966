import { authService } from '../../services/auth.service';
import { Button, Form, Input, message, Space } from 'antd';
import { AuthenticationForm, AuthImageB, CreatePassword, LoginLink } from '../SharedComponents/AuthComp/AuthComp';
import { SubTextPassword, SubText, SubTextHeader } from '../SharedComponents/AuthComp/AuthComp';
import { buttonWidth, formInput } from 'utils/styles';

const { Item } = Form;
const { Password } = Input;

export default function CreateUserPassword() {
  const [form] = Form.useForm();

  const createPassword = async (values: any) => {
    return values.password.length < 8 || values.rePassword.length < 8
      ? message.error('Password is not valid')
      : values.password !== values.password.replace(/[^\x00-\x7F]+/gi, '')
      ? message.error('Password is not valid')
      : values.password !== values.rePassword
      ? message.error('Password mismatch')
      : (await authService.createPassword(values.password), message.success('Success'));
  };

  return (
    <>
      <AuthImageB />
      <LoginLink />
      <AuthenticationForm>
        <Space direction="vertical">
          {CreatePassword()}
          <Form form={form} onFinish={createPassword}>
            <Item key="password" name="password">
              <Password type="password" placeholder="Enter the password here" style={formInput} />
            </Item>
            <Item key="rePassword" name="rePassword">
              <Password type="password" placeholder="Re-enter the password here" style={formInput} />
            </Item>
            <Item>
              <Button type="primary" htmlType="submit" style={buttonWidth}>
                Create Password
              </Button>
            </Item>
          </Form>
        </Space>
      </AuthenticationForm>
      <SubText>
        <SubTextHeader /> <SubTextPassword />
      </SubText>
    </>
  );
}
