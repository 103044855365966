import './Authentication.scss';
import { useState } from 'react';
import { authService } from '../../services/auth.service';
import { handleInputChange } from '../../utils/common';
import { Typography, Button, Input, notification, Space } from 'antd';
import { buttonBorder, formButton } from 'utils/styles';
import { capitalLetter } from 'utils/nameFormat';
import { AuthImageB, AuthenticationForm, LoginHeading, SignupLink } from '../SharedComponents/AuthComp/AuthComp';
import { SubText, SubTextHeader, SubTextLogin } from '../SharedComponents/AuthComp/AuthComp';

const { Text } = Typography;

const FindTenantForm = () => {
  const lasetUsedEmail = window.localStorage.getItem('LastTenant_email') || undefined;
  const [email, setEmail] = useState('');
  const [lastEmail, setLastEmail] = useState(lasetUsedEmail);
  const [tenants, setTenants] = useState([]);

  const onTenantsRequest = async email => {
    debugger;
    const res = await authService.tenants(email);
    return res.error
      ? notification.error({ key: 'Login', message: `status ${res.status}`, description: res.data.message })
      : // Set the email in local storage to remember the last used email
        (window.localStorage.setItem('LastTenant_email', email),
        setLastEmail(email),
        res.data.length === 1
          ? window.location.replace(`https://${res.data[0]}.${window.location.host}/#/login/email/${encodeURIComponent(email)}`)
          : res.data.length === 0
          ? // @ts-ignore
            notification.warn({ key: 'Login', description: 'Your email not found. Do you want sign up?' })
          : null,
        setTenants(res.data));
  };

  const emailButtonFind = () => (
    <Button type="primary" disabled={lastEmail === email} onClick={() => onTenantsRequest(email)} style={formButton}>
      Login
    </Button>
  );

  return (
    <form onSubmit={() => onTenantsRequest({ email })}>
      <Space direction="vertical">
        <Text strong> Email </Text>
        <Input placeholder="Enter Email Here" onChange={handleInputChange(setEmail)} value={email} />
        {lastEmail !== email || tenants.length === 0
          ? emailButtonFind()
          : tenants.map(tenant => {
              const handleOpenTenant = () => window.location.replace(`https://${tenant}.${window.location.host}/`);
              return (
                <Button key={tenant} type="primary" onClick={handleOpenTenant} style={buttonBorder}>
                  Open Tenant {capitalLetter(tenant)}
                </Button>
              );
            })}
      </Space>
    </form>
  );
};

export default function FindTenantPage() {
  return (
    <>
      <AuthImageB />
      <SignupLink />
      <AuthenticationForm>
        {LoginHeading()}
        <FindTenantForm />
      </AuthenticationForm>
      <SubText>
        <SubTextHeader /> <SubTextLogin />
      </SubText>
    </>
  );
}
