import { Typography, Space, Switch, Button, Alert, notification } from 'antd';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { useEffect, useState } from 'react';
import { deploymentService } from 'services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { buttonSize, bannerStyle } from 'utils/styles';

const { Text } = Typography;

export const PauseApplicationBtn = (props: { deployment: iDeployment }) => {
  const [autoOff, setAutoOff] = useState<number>(props?.deployment?.autoOff);
  useEffect(() => {
    setAutoOff(props?.deployment?.autoOff);
  }, [props?.deployment?.autoOff]);

  const autoOffFunctions = async checked => {
    setAutoOff(checked);
    await deploymentService.updateDeployment(deployment.id, { autoOff: checked });
    notification.success({ description: `Saved`, message: '' });
  };

  const deploymentPause = async () => {
    await deploymentService.updateDeployment(deployment.id, { isEnabled: !deployment.isEnabled, timeStart: Math.round(Date.now() / 1000) });
    notification.success({ description: `Saved`, message: '' });
  };

  const deployment = props.deployment;
  const alertDescription = (
    <Space direction="vertical">
      <Text> Pausing your service will temporarily suspend it and stop all running instances. </Text>
      <Text> If Application is paused, You can reactivate it at any time by clicking "Resume." </Text>
      <Space direction="horizontal">
        <TipLeft tip="Turn off Application in 48 hours after launch. This action can loss Application data">
          <Switch disabled={!deployment} checked={!!autoOff} onChange={autoOffFunctions} />
        </TipLeft>
        <Text> Pause Application 48 hours launch </Text>
      </Space>
    </Space>
  );

  const alertAction = (
    <TipLeft tip="It will remove Application from Cluster. This action can loss Application data">
      <Button loading={!deployment} onClick={deploymentPause} style={buttonSize}>
        {deployment.isEnabled ? 'Pause Service' : 'Resume Service'}
      </Button>
    </TipLeft>
  );

  return <Alert message={<Text strong> Pause Application </Text>} description={alertDescription} action={alertAction} style={bannerStyle} />;
};
