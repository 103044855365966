import { Link } from 'react-router-dom';
import { Breadcrumb, Typography } from 'antd';
import { HomeTwoTone, RightOutlined } from '@ant-design/icons';
import { breadcrumbColor } from 'utils/styles';

const { Text } = Typography;
const { Item } = Breadcrumb;

export default function NhBreadcrumb() {
  const currentPath = window.location.hash.replace('#/', '').split('/');
  const breadcrumbLinks = { deployment: '/', project: '/projects', cluster: '/clusters' };
  const homeIcon = <HomeTwoTone twoToneColor="#00A58E" />;

  const breadcrumbPaths = Object.fromEntries(
    Object.entries(breadcrumbLinks).map(([key, path]) => [
      key,
      <Link key={key} to={path} className="breadcrumbText" style={breadcrumbColor}>
        {['deployment', 'project', 'cluster'].includes(key) ? homeIcon : key.charAt(0).toUpperCase() + key.slice(1)}
      </Link>,
    ]),
  );

  const breadcrumbItems =
    currentPath[0] === 'deployment'
      ? [breadcrumbPaths.deployment, `Application  ${currentPath[1]}`]
      : currentPath[0] === 'new'
      ? [breadcrumbPaths.deployment, 'New deployment']
      : currentPath[0] === 'project'
      ? [breadcrumbPaths.project, `Template ${currentPath[1]}`]
      : currentPath[0] === 'clusters'
      ? [breadcrumbPaths.cluster, currentPath[1] ? `Cluster ${currentPath[1]}` : 'All clusters']
      : currentPath[0] === 'cluster'
      ? [breadcrumbPaths.cluster, currentPath[2] ? `New cluster ${currentPath[2]}` : 'New']
      : currentPath;

  const breadcrumbData = () => (
    <Breadcrumb separator={<RightOutlined />}>
      {breadcrumbItems.map((item, i) => (
        <Item key={i}>
          <Text style={breadcrumbColor}> {typeof item !== 'string' ? item : item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()} </Text>
        </Item>
      ))}
    </Breadcrumb>
  );

  if (breadcrumbItems.length < 2) return null;
  return breadcrumbData();
}
