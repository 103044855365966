import { Fragment, useState } from 'react';
import { PROJECT_NAME } from 'interface/common';
import { TenantConfiguration } from 'components/Tenant/TenantConfiguration';
import { Button, Divider, Drawer, Space, Typography } from 'antd';
import { QuestionCircleFilled, QuestionCircleOutlined, BellOutlined, SettingOutlined, BellTwoTone, ExperimentOutlined } from '@ant-design/icons';
import { TipBottom } from 'components/SharedComponents/Tooltip/Tooltip';

const { Title, Text } = Typography;

export const NhHelpDrawer = props => {
  const [drawerState, setDrawerState] = useState({ help: false, notifications: false, themes: false });

  const toggleDrawer = (drawerType: string, open: boolean) => setDrawerState(prevState => ({ ...prevState, [drawerType]: open }));

  const buttonsData = type => {
    const buttonIcon = {
      help: { title: 'Help', icon: <QuestionCircleOutlined /> },
      notifications: { title: 'Notifications', icon: <BellOutlined /> },
      themes: { title: 'Themes', icon: <SettingOutlined /> },
    };

    const buttonTooltip = (type: string | number) => buttonIcon[type] || { title: '', icon: null };

    const buttonIcons = (
      <TipBottom tip={buttonTooltip(type).title} key={type}>
        {buttonTooltip(type).icon}
      </TipBottom>
    );

    const handleButtonClick = () => toggleDrawer(type, true);

    return (
      <Space role="button" tabIndex={0} key={`buttons` + type} onClick={handleButtonClick} onKeyUp={handleButtonClick}>
        {props.openBtnUI ? props.openBtnUI : <Button type="text" icon={buttonIcons} />}
      </Space>
    );
  };

  const drawersData = (type: string) => {
    const sectionContent1 = (
      <Space key="S1" direction="horizontal">
        <Button type="primary" icon={<QuestionCircleFilled />} />
        <Text> Look out for this icon to access help and related resources. </Text>
      </Space>
    );

    const sectionContent2 = (
      <Space key="S2" direction="vertical">
        <Text> Learn how to utilize {PROJECT_NAME} full potential using guided tutorials, and in-depth help guides. </Text>
        <Button type="primary" block>
          Start Learning
        </Button>
      </Space>
    );

    const sectionContent3 = (
      <Space key="S3" direction="vertical">
        <Text> Reach out to our support team for further questions and assistance </Text>
        <Button type="primary" block>
          Contact Us
        </Button>
      </Space>
    );

    const sections = [
      { key: 'section1', title: 'Need help?', content: sectionContent1, divider: true },
      { key: 'section2', title: 'How to guide', content: sectionContent2, divider: true },
      { key: 'section3', title: 'Help & Support', content: sectionContent3, divider: false },
    ];

    const drawerHelp = () => (
      <Space direction="vertical">
        {sections.map(({ key, title, content, divider }) => (
          <Fragment key={key}>
            <Title level={4}> {title} </Title>
            {content}
            {divider && <Divider />}
          </Fragment>
        ))}
      </Space>
    );

    const drawerNotifications = () => (
      <Space direction="horizontal">
        <BellTwoTone twoToneColor={'#00A58E'} />
        <Text> Notifications Coming Soon... </Text>
      </Space>
    );

    const drawerContents = { help: drawerHelp(), notifications: drawerNotifications(), themes: <TenantConfiguration /> };

    return (
      <Drawer
        width={300}
        key={`drawers ${type}`}
        title={type.charAt(0).toUpperCase() + type.slice(1)}
        onClose={() => toggleDrawer(type, false)}
        open={drawerState[type]}
      >
        {drawerContents[type]}
      </Drawer>
    );
  };

  const experimentData = () => (
    <TipBottom tip="API documentation" key="api">
      <a href={`https://${window.location.host}/unifie-api/graphql/v1`} target="_blank">
        <Button type="text" icon={<ExperimentOutlined />} />
      </a>
    </TipBottom>
  );

  const appDrawer = () => {
    const buttonOptions = ['help', 'notifications', 'themes'];
    return (
      <Space direction="horizontal">
        {experimentData()}
        {buttonOptions.map(type => (
          <Space key={type}>
            {buttonsData(type)}
            {drawersData(type)}
          </Space>
        ))}
      </Space>
    );
  };

  return appDrawer();
};
