import moment from 'moment';
import InfoUpdate from '../OverView/InfoUpdateComponent';
import { useHistory, useParams } from 'react-router-dom';
import { useApiQuery, useUser } from '../../../utils/common';
import { auditLogsService } from 'services/auditLogs.service';
import { iDeployment } from 'shared/deployment';
import { TitleUIRow } from 'layout/TitleUI';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { Typography, Collapse, Skeleton, Tag, Space, Alert } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { autoOverflow, bannerStyle, spaceWidth } from 'utils/styles';
import { PROJECT_NAME } from 'interface/common';
import { TipBottom, TipRight } from 'components/SharedComponents/Tooltip/Tooltip';
import { formatUserName } from 'utils/nameFormat';

interface iDHistory {
  createdAt: moment.MomentInput;
  data: { diff: { [x: string]: any } };
  userName: any;
  name: string;
}

const { Text } = Typography;
const { Panel } = Collapse;

export default function AuditLogs(props: { deployment: iDeployment }) {
  // @ts-ignore
  let { id } = useParams();
  const history = useHistory();
  const user = useUser();
  const [dHistory, dHistoryError, dHistoryLoading] = useApiQuery(() => auditLogsService.getDeploymentLogsHistory(id));

  if (!userHasPermission(user, UserPermissions.DeploymentHistory)) return <UserNoPermissions permission={UserPermissions.DeploymentHistory} />;
  if (dHistoryError) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }
  return (
    <Space direction="vertical" style={spaceWidth}>
      <Skeleton active={true} loading={dHistoryLoading}>
        <TitleUIRow title={`Audit logs: ${props.deployment.name}`} />
        <Collapse size="small">
          {(dHistory || []).map((v: iDHistory, key: number) => {
            const panelData = () => {
              const userError = `Anonymous ${PROJECT_NAME} User`;
              const alertMessage = (
                <TipRight tip={v.userName || userError}>
                  <Text> {formatUserName(v.userName) || userError} </Text>
                </TipRight>
              );

              const alertAction = () => {
                return Object.keys(v.data?.diff || []).map(key => {
                  const dataKey = v.data.diff[key];
                  if (key == 'env' || key == 'serviceVars') {
                    return Object.keys(dataKey || []).map(envKey => {
                      const dataKeys = [
                        { key: dataKey[envKey].old, color: 'gold', tip: 'Old env value' },
                        { key: dataKey[envKey].new, color: 'green', tip: 'New env value' },
                      ];

                      const renderData = dataKeys
                        .filter(({ key }) => key)
                        .map(({ key, color, tip }) => (
                          <Space direction="horizontal">
                            <TipBottom tip="ENV name">
                              <Tag color="red"> {envKey} </Tag>
                            </TipBottom>
                            <ArrowRightOutlined />
                            <TipBottom tip={tip}>
                              <Tag color={color}> {JSON.stringify(key)} </Tag>
                            </TipBottom>
                          </Space>
                        ));

                      return <Space direction="vertical"> {renderData} </Space>;
                    });
                  }
                  const newVal = dataKey === true ? 'true' : dataKey === false ? 'false' : JSON.stringify(dataKey);
                  return (
                    <li key={key}>
                      <InfoUpdate keyElement={key} valueElement={newVal} />
                    </li>
                  );
                });
              };

              return <Alert message={alertMessage} action={alertAction()} style={bannerStyle} />;
            };

            const panelExtra = moment(v.createdAt).format('DD-MM-YYYY ~ hh:mm:ss A');
            const panelHeader = v.name.replace(/([.*+?^="!:${}()|\[\]\/\\])/g, '');

            return (
              <Panel key={key} header={panelHeader} extra={panelExtra} style={{ ...autoOverflow, wordBreak: 'break-all' }}>
                {panelData()}
              </Panel>
            );
          })}
        </Collapse>
      </Skeleton>
      <Text />
    </Space>
  );
}
