import { Link } from 'react-router-dom';
import { Button, Typography, Space } from 'antd';
import { formInput, formButton } from 'utils/styles';
import { SignupLink, SubText, SubTextHeader, SubTextLogin } from 'components/SharedComponents/AuthComp/AuthComp';
import { AuthImageC, AuthenticationForm } from 'components/SharedComponents/AuthComp/AuthComp';

const { Title, Text } = Typography;

export default function ErrorPage_github_link_error() {
  return (
    <>
      <AuthImageC />
      <SignupLink />
      <AuthenticationForm>
        <Title level={2}> Error </Title>
        <Space direction="vertical" style={formInput}>
          <Text strong> Please log in to your account with email and password. Then link you account with Github </Text>
          <Space direction="horizontal">
            <Link to="/">
              <Button style={formButton} type="primary" data-cy="main-page-link">
                Go to main page
              </Button>
            </Link>
          </Space>
        </Space>
      </AuthenticationForm>
      <SubText>
        <SubTextHeader /> <SubTextLogin />
      </SubText>
    </>
  );
}
