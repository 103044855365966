import './Authentication.scss';
import { Fragment, useState } from 'react';
import { authService } from '../../services/auth.service';
import { handleInputChange } from '../../utils/common';
import { Typography, Button, Input, notification, Space, Modal } from 'antd';
import { formInput, formButton } from 'utils/styles';
import { AuthenticationForm, AuthImageC, LoginLink } from '../SharedComponents/AuthComp/AuthComp';
import { SignupHeading, SubText, SubTextHeader, SubTextSignup } from '../SharedComponents/AuthComp/AuthComp';
import { PROJECT_NAME } from 'interface/common';

const { Text } = Typography;

const onFormSubmit = async ({ email, organization, firstName, lastName }: any) => {
  notification.info({ key: 'RequestAccess', description: 'Please wait a second...', message: '' });
  try {
    const res = await authService.requestAccess({ email, organization, firstName, lastName });
    return res.status === 201
      ? (notification.success({ key: 'RequestAccess', message: 'Success' }),
        Modal.info({
          centered: true,
          onOk: () => window.location.replace(`https://${organization}.${window.location.host.replace('auth.', '')}/`),
          title: `You have successfully signed up`,
          content: (
            <>
              <p> Thank you for your interest in the {PROJECT_NAME} service. </p>
              <p>
                We sent an email to <b> {email} </b> with further instructions. Please check your email and follow the instructions.
              </p>
            </>
          ),
        }),
        true)
      : (notification.error({ key: 'RequestAccess', description: res.data.message, message: '' }), false);
  } catch (error) {
    notification.error({ key: 'RequestAccess', description: 'An error occurred', message: '' });
    return false;
  }
};

export default function RequestAccess() {
  const [login, setLogin] = useState('');
  const [organization, setOrganization] = useState('');
  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');

  const signUpForm = () => {
    const inputFields = [
      { label: 'First Name', placeholder: 'Enter First Name Here', stateUpdater: setFName },
      { label: 'Last Name', placeholder: 'Enter Last Name Here', stateUpdater: setLName },
      { label: 'Organization', placeholder: 'Enter Organization Name Here', stateUpdater: setOrganization },
      { label: 'Email ID', placeholder: 'Enter Email ID Here', stateUpdater: setLogin },
    ];
    return inputFields.map((field, index) => (
      <Fragment key={index}>
        <Text strong> {field.label} </Text>
        <Input placeholder={field.placeholder} onChange={handleInputChange(field.stateUpdater)} />
      </Fragment>
    ));
  };

  const requestAccessFormData = () => {
    const handleForm = () => onFormSubmit({ email: login, organization, firstName: fName, lastName: lName });
    return (
      <AuthenticationForm>
        {SignupHeading()}
        <form onSubmit={handleForm}>
          <Space direction="vertical" style={formInput}>
            {signUpForm()}
            <Text />
            <Button type="primary" onClick={handleForm} style={formButton}>
              Sign Up
            </Button>
          </Space>
        </form>
      </AuthenticationForm>
    );
  };

  return (
    <>
      <AuthImageC />
      <LoginLink />
      {requestAccessFormData()}
      <SubText>
        <SubTextHeader /> <SubTextSignup />
      </SubText>
    </>
  );
}
