import { ArrowRightOutlined } from '@ant-design/icons';
import { Space, Tag, Typography } from 'antd';

const { Text } = Typography;

export const EmptyDescription = () => {
  return (
    <Space direction="vertical">
      <Text>
        On the left column there will be a configurator with a visual interface, and on the right there will be a final yaml template for kubernetes.
      </Text>
      <Text>
        If you have a ready-made yaml template, then paste it into a text editor.
        <Tag color="green">
          <ArrowRightOutlined />
        </Tag>
      </Text>
      <Text> If you only have a Docker image, then start the configuration by creating a Application. </Text>
      <Text> You can also create other types of kubernetes objects. </Text>
    </Space>
  );
};
