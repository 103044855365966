import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useRegionsController_getRegionById } from 'services/regions.service';
import { useGqlDeploymentById } from 'services/deployment.service';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { iMyUserData } from 'shared/user';
import { iDeployment } from 'shared/deployment';
import { Menu } from 'antd';
import { AppstoreAddOutlined, AppstoreOutlined, BarChartOutlined, BarsOutlined, BranchesOutlined, BugOutlined } from '@ant-design/icons';
import { CheckCircleOutlined, CloudServerOutlined, CloudUploadOutlined, ClusterOutlined, CodeOutlined, DashboardOutlined } from '@ant-design/icons';
import { DeploymentUnitOutlined, PauseCircleOutlined, PieChartOutlined, PlusSquareTwoTone, ReadOutlined, SettingOutlined } from '@ant-design/icons';
import { ToolOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import { orangeColor, redColor, siderStyle } from 'utils/styles';
import { useAuthedQuery } from 'utils/qlAuth';
import { useUser } from 'utils/common';

export function SideNavBarLevel1() {
  const user = useUser();
  const path = window.location.hash != '' ? window.location.hash.replace('#/', '') : window.location.pathname.replace('/', '');
  const currentPath = path.split('/');
  const selectedMenuItems = ['/' + path];
  const history = useHistory();
  const [current, setCurrent] = useState(selectedMenuItems);

  selectedMenuItems.push(...currentPath.map((_, index) => `/${currentPath.slice(0, index + 1).join('/')}`));

  useEffect(() => {
    const selectedMenuItems = ['/' + path];
    selectedMenuItems.push(...currentPath.map((_, index) => `/${currentPath.slice(0, index + 1).join('/')}`));
    setCurrent(selectedMenuItems);
  }, [path]);

  const items = [
    { label: 'Applications', key: `/`, icon: <CloudServerOutlined /> },
    { label: 'Clusters', key: `/clusters`, icon: <ClusterOutlined />, permission: UserPermissions.ClusterMange },
    { label: 'Team', key: `/users`, icon: <UserOutlined />, permission: UserPermissions.UsersMange },
    { label: 'Activity', key: `/audit-logs`, icon: <ReadOutlined />, permission: UserPermissions.AuditLogs, condition: true },
    // { label: 'Templates', key: `/projects`, icon: <DeploymentUnitOutlined />, permission: UserPermissions.ProjectsMange },
    // { label: 'Charts', key: `/charts`, icon: <PieChartOutlined /> },
    // { label: 'Integrations', key: `/integrations`, icon: <ExperimentOutlined />, permission: UserPermissions.ClusterMange, condition: true },
    // { label: 'Tariffs', key: `/tariffs`, icon: <WalletOutlined />, permission: UserPermissions.Tariffs, condition: isUnifie() || isDevLocalhost() },
  ]
    .filter(item => {
      if (item.condition === true) {
        return userHasPermission(user, item.permission);
      }
      return item.condition === undefined || (item.condition && userHasPermission(user, item.permission));
    })
    .map(({ permission, condition, ...rest }) => rest);

  const handleMenuClick = e => {
    setCurrent(e.key);
    history.push(e.key);
  };
  const menuItems = items.filter(v => v).map(item => ({ ...item, label: item.key ? <Link to={item.key}> {item.label} </Link> : item.label }));

  return <Menu mode="horizontal" selectedKeys={current} onClick={handleMenuClick} items={menuItems} style={siderStyle} />;
}

export function hasSideNavBarLevel2(user: iMyUserData): boolean {
  const currentPath = (window.location.hash != '' ? window.location.hash.replace('#/', '') : window.location.pathname.replace('/', '')).split('/');
  const excludedPath = ['', 'users', 'clusters', 'audit-logs', 'projects', 'new-application', 'new-from-template', 'new-intro'];

  if (currentPath[0] === 'clusters' && currentPath[1]) {
    return currentPath[1] !== `new`;
  }

  if (currentPath[0] === 'cluster' && currentPath[1] && !currentPath[2]) {
    return currentPath[1] !== `new`;
  }
  if (excludedPath.includes(currentPath[0])) {
    return false;
  }
  return true;
}

/** @returns Main menu for the application and Can change menu items based on current URL */
export function SideNavBarLevel2() {
  const user = useUser();
  const path = window.location.hash != '' ? window.location.hash.replace('#/', '') : window.location.pathname.replace('/', '');
  const currentPath = path.split('/');
  const selectedMenuItems = ['/' + path];
  const history = useHistory();
  const [current, setCurrent] = useState(selectedMenuItems);

  // Get data about the cluster if we really on the cluster page
  const qCluster = useRegionsController_getRegionById(
    currentPath[0] === 'clusters' && /^[0-9]+$/.test(currentPath[1]) ? Number(currentPath[1]) : null,
  );

  let deploymentId = null;
  if (currentPath[0] === 'app' && currentPath[1]) {
    deploymentId = currentPath[1];
  }

  for (let i = 0; i < currentPath.length; i++) {
    selectedMenuItems.push(`/${currentPath.slice(0, i + 1).join('/')}`);
  }

  let items: any[] = [];

  const deployment: iDeployment = useGqlDeploymentById(Number(deploymentId))?.data?.DeploymentsController_getDeployment;
  const project = deployment?.ProjectModel;

  let projectId = project?.id || null;
  if (!projectId && Number(currentPath[1]) && currentPath[0] === 'project') {
    projectId = Number(currentPath[1]);
  }

  // In case if we in deployment creation or configuration page - will add services to the menu
  const query = useAuthedQuery(
    gql`
      query ProjectController_getServiceList($projectId: Int!) {
        ProjectController_getServiceList(projectId: $projectId) {
          name
          uiType
        }
      }
    `,
    { skip: !Number(projectId), variables: { projectId: Number(projectId) } },
  );

  const projectServices = query?.data?.ProjectController_getServiceList || [];

  const newDeployment = () => [
    { label: 'Basic details', key: `/new`, icon: <CloudServerOutlined />, children: null },
    { label: 'Select application target', key: `/new`, icon: <PieChartOutlined />, children: null, disabled: true },
    ...['Customise', 'Deploy'].map(label => ({ label, key: `/new`, icon: <DeploymentUnitOutlined />, children: null, disabled: true })),
  ];

  const clusterPrimary = () => {
    const baseKey = `/clusters/${currentPath[1]}`;
    const items = [
      { label: 'Overview', icon: <CloudServerOutlined /> },
      { label: 'Settings', icon: <ToolOutlined /> },
      { label: 'Nodes', icon: <AppstoreOutlined /> },
      { label: 'PVC', icon: <AppstoreOutlined /> },
      { label: 'Monitoring', icon: <BarChartOutlined /> },
      { label: 'Integrations', icon: <AppstoreOutlined /> },
      { label: 'Agent status', icon: <AppstoreOutlined /> },
      { label: 'Sync logs', icon: <AppstoreOutlined /> },
      { label: 'Audit logs', icon: <ReadOutlined /> },
      { label: 'Events', icon: <ReadOutlined /> },
      { label: 'Danger zone', icon: <BarChartOutlined /> },
    ];
    return items.map(({ label, icon }) => ({ label, key: `${baseKey}/${label.replace(/ /g, '-').toLowerCase()}`, icon, children: null }));
  };

  const clusterSecondary = () => {
    const baseKey = `/cluster/new/${currentPath[2]}`;
    const items = [
      { label: 'Basic details', path: `/basic` },
      { label: 'Common data', path: `/step3` },
      { label: 'Integrations', path: `/integrations` },
    ];
    return items.map(({ label, path }) => ({ label, key: `${baseKey}${path}`, icon: <BarChartOutlined />, children: null }));
  };

  const createDeployment = () => {
    const baseKey = `/app/${currentPath[1]}/setting`;
    const items = [
      // { label: 'Basic Details', path: `/basic`, icon: <CloudServerOutlined />, disabled: true },
      { label: 'Overview', path: `/details`, icon: <PieChartOutlined />, disabled: false },
      { label: 'Customise', path: `/customise`, icon: <DeploymentUnitOutlined /> },
      { label: 'Deploy', path: `/deploy`, icon: <DeploymentUnitOutlined /> },
    ];
    return items.map(({ label, path, icon, disabled }) => ({ label, key: `${baseKey}${path}`, icon, children: null, disabled }));
  };

  const createDeployments = () => {
    const baseKey = `/app/${currentPath[1]}`;
    const items = [
      { label: 'Overview', key: `${baseKey}/overview`, icon: <CloudServerOutlined /> },
      userHasPermission(user, UserPermissions.DeploymentMange) && {
        label: 'Services',
        key: `${baseKey}/services/services-requests`,
        icon: <CloudServerOutlined />,
      },
      userHasPermission(user, UserPermissions.DeploymentWebSSH) && { label: 'Web SSH', key: `${baseKey}/webssh`, icon: <CodeOutlined /> },
      { label: 'Monitoring', key: `${baseKey}/monitoring`, icon: <CodeOutlined /> },
      (userHasPermission(user, UserPermissions.DeploymentLogs) || userHasPermission(user, UserPermissions.DeploymentHistory)) && {
        label: 'Logs',
        key: `${baseKey}/logs`,
        icon: <DeploymentUnitOutlined />,
      },
      userHasPermission(user, UserPermissions.DeploymentSpecs) && {
        label: 'Specifications',
        key: `${baseKey}/specs`,
        icon: <DeploymentUnitOutlined />,
      },
      userHasPermission(user, UserPermissions.DeploymentMange) && {
        label: 'Advanced Settings',
        key: `${baseKey}/advanced/general`,
        icon: <DeploymentUnitOutlined />,
      },
      { label: 'Container storage', key: `${baseKey}/storage/pvc`, icon: <CloudServerOutlined /> },
      { label: 'Danger zone', key: `${baseKey}/settings/delete`, icon: <CloudServerOutlined /> },
      // { label: 'Audit logs', key: `/app/${currentPath[1]}/settings/audit-logs`, icon: <DeploymentUnitOutlined />, children: null },
    ].filter(Boolean);
    return items.map(item => ({ ...item, children: null }));
  };

  const createProjects = () => [
    { label: 'Overview', key: `/project/${currentPath[1]}/settings/general`, icon: <CloudServerOutlined />, children: null },
    { label: 'Services', key: `/project/${currentPath[1]}/settings/services`, icon: <PieChartOutlined />, children: null },
    { label: 'Project Variables', key: `/project/${currentPath[1]}/settings/env`, icon: <DeploymentUnitOutlined />, children: null },
    { label: 'Cluster & Node Details', key: `/project/${currentPath[1]}/settings/provisioner`, icon: <DeploymentUnitOutlined />, children: null },
    { label: 'Pull secrets', key: `/project/${currentPath[1]}/settings/pull-secrets`, icon: <DeploymentUnitOutlined />, children: null },
    { label: 'CI/CD details', key: `/project/${currentPath[1]}/settings/ci-cd`, icon: <DeploymentUnitOutlined />, children: null },
    { label: 'Advanced', key: `/project/${currentPath[1]}/settings/project-files`, icon: <DeploymentUnitOutlined />, children: null },
    { label: 'Project logs', key: `/project/${currentPath[1]}/settings/logs`, icon: <CloudServerOutlined />, children: null },
    { label: 'Versions', key: `/project/${currentPath[1]}/settings/versions`, icon: <DeploymentUnitOutlined />, children: null },
  ];

  const jobsPath = () => ({ label: 'Jobs', key: `/clusters/${currentPath[1]}/jobs`, icon: <AppstoreOutlined />, children: null });

  // Allow to replace menu items for some pages - we can do it base on current URL and replace menu items for the page `/new`
  if (currentPath[0] === 'new') {
    items = newDeployment();
  } else if (currentPath[0] === 'app') {
    items = [];

    const baseKey = `/app/${currentPath[1]}`;

    if (deployment?.isReady) {
      // Show only if deployment is ready to deploy

      const YamlIcons = deployment?.specsErrors ? (
        <WarningOutlined style={redColor} />
      ) : deployment?.specsWarns ? (
        <WarningOutlined style={orangeColor} />
      ) : null;

      items.push(
        { label: 'Overview', key: `${baseKey}/status/overview`, icon: <DashboardOutlined /> },
        userHasPermission(user, UserPermissions.DeploymentMange) && {
          label: 'Service details',
          key: `${baseKey}/services/services-requests`,
          icon: <CloudServerOutlined />,
        },
        (userHasPermission(user, UserPermissions.DeploymentLogs) || userHasPermission(user, UserPermissions.DeploymentSpecs)) && {
          label: <> Diagnostic {YamlIcons} </>,
          key: `${baseKey}/logs`,
          icon: <BugOutlined />,
        },
        // { label: 'Settings', key: `${baseKey}/settings/delete`, icon: <SettingOutlined /> },
      );
    } else {
      items.push({ label: 'Git', key: `${baseKey}/configuration/git`, icon: <BranchesOutlined />, children: null });
    }

    items.push({
      label: 'Settings',
      key: `${baseKey}/configuration/settings/${deployment?.isReady ? 'danger-zone' : 'general'}`,
      icon: <SettingOutlined />,
      children: null,
    });

    if (userHasPermission(user, UserPermissions.ProjectsMange)) {
      items.push({
        label: 'Services',
        key: `${baseKey}/configuration/new-service`,
        icon: <AppstoreAddOutlined />,
        children: [
          // { label: 'Services are below', children: null, disabled: true },
          ...(projectServices.length > 0 &&
          (userHasPermission(user, UserPermissions.ProjectsMange) || userHasPermission(user, UserPermissions.DeploymentMange))
            ? projectServices.map(({ name }) => {
                const path = `${baseKey}/configuration/services/${name}`;
                return {
                  children: null,
                  label: <Link to={path}> {name} </Link>,
                  key: path,
                  icon: deployment?.services?.[name]?.enabled ? (
                    <CheckCircleOutlined style={{ color: 'grren' }} />
                  ) : (
                    <PauseCircleOutlined style={{ color: 'red' }} />
                  ),
                };
              })
            : []),
          projectServices.length > 0 ? { type: 'divider' } : null,
          {
            label: 'Add new service',
            icon: <PlusSquareTwoTone twoToneColor="#00A58E" />,
            key: `${baseKey}/configuration/new-service/add`,
            children: null,
          },
        ],
      });
    }

    if (userHasPermission(user, UserPermissions.DeploymentHistory) && deployment?.isReady) {
      items.push({ label: 'Activity', key: `${baseKey}/activity`, icon: <BarsOutlined />, children: null });
    }
    if (!deployment?.isReady) {
      items.push({ label: 'Details', key: `${baseKey}/setting/details`, icon: <CloudUploadOutlined />, children: null });
      items.push({ label: 'Customise', key: `${baseKey}/setting/customise`, icon: <CloudUploadOutlined />, children: null });
      items.push({ label: 'Confirm and Deploy', key: `${baseKey}/setting/deploy`, icon: <CloudUploadOutlined />, children: null });
    }
  } else if (currentPath[0] === 'cluster' && currentPath[1] === 'new') {
    items = currentPath[2] ? clusterSecondary() : undefined;
  } else if (currentPath[0] === 'clusters' && /^[0-9]+$/.test(currentPath[1])) {
    /** When qCluster is null - than we are just try to check if we have menu items in the current page
     * Cluster page always have some menu items.
     * @todo: to avoid this bad logic we need to improve function "hasSideNavBarLevel2" */
    if (qCluster === null) {
      items = clusterPrimary();
    } else {
      const overviewPath = [{ label: 'Overview', key: `/clusters/${currentPath[1]}/overview`, icon: <CloudServerOutlined />, children: null }];
      if (qCluster?.loading || !user) {
        items = overviewPath;
      } else if (qCluster?.region?.tenant && qCluster?.region?.tenant !== user.tenant) {
        items = overviewPath;
      } else if (qCluster?.region?.tenant === user.tenant) {
        items = clusterPrimary();
        if (qCluster?.region?.cloudProvider === 'aws') {
          items.push(jobsPath());
        }
      }
    }
  } else if (['deployment'].includes(currentPath[0]) && currentPath[2] === 'setting') {
    items = createDeployment();
  } else if (['deployment'].includes(currentPath[0])) {
    items = createDeployments();
  } else if (currentPath[0] === 'project') {
    items = createProjects();
  }

  const menuItems = (items || []).filter(v => v).map(item => ({ ...item, label: item.key ? <Link to={item.key}> {item.label} </Link> : item.label }));
  const menuPop = (node: any) => node.parentNode;
  const handleMenuClick = e => {
    setCurrent(e.key);
    history.push(e.key);
  };

  if (menuItems.length === 0) {
    return null;
  }

  return <Menu mode="vertical" getPopupContainer={menuPop} selectedKeys={current} onClick={handleMenuClick} items={menuItems} style={siderStyle} />;
}
