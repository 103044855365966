import _ from 'lodash';
import * as yaml from 'js-yaml';
import Editor from '@monaco-editor/react';
import { useState } from 'react';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { iProject } from 'interface/project';
import { getExampleYaml, KindLib } from './kindLib';
import { stringToYaml } from 'shared/yaml';
import { OneKubeItemTabUI } from './OneKubeItemTabUI';
import { Alert, Button, Collapse, Dropdown, notification, Result, Skeleton, Space, Tabs, Row, Col, Typography } from 'antd';
import { DeleteOutlined, DownOutlined, FlagOutlined } from '@ant-design/icons';
import { spaceWidth, buttonWidth } from 'utils/styles';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';
import { EmptyTitle } from './utils/EmptyTitle';
import { EmptyDescription } from './utils/EmptyDescription';
import { iCodeObjectBasic, PatchYamlAction, patchYamlCode } from './utils/yaml-tools';
import { iProjectModel } from 'shared/deployment';

interface iCodeObjectArray extends iCodeObjectBasic {
  ui: any;
  helpers?: any;
}

interface iYamlEditorProps {
  project: iProjectModel;
  fileName: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  mainKindType?: string;
  emptyButtonTitle?: any;
  emptyTitle?: any;
  emptyDescription?: any;
  emptyIco?: any;
  hideOtherObjectTypes?: boolean;
}

const { Text } = Typography;
const { Panel } = Collapse;

/**
 * @param param0
 * @returns
 * @link https://www.freecodecamp.org/news/how-to-build-react-based-code-editor/
 * @link https://www.npmjs.com/package/react-simple-code-editor
 */
export const YamlEditor = (props: iYamlEditorProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { project, fileName } = props;
  const [content, fError, fLoader] = useApiQuery(() => projectService.getFileContent(project.id, fileName), [fileName]);
  const [isChanged, setChanged] = useState(false);
  const [newCode, setNewCode] = useState(null);

  if (fLoader || fError) return <Skeleton active={true} loading={true} />;

  /**
   * Save all config to the file on the server backend
   * @param value
   */

  const handleSaveFile = async value => {
    setChanged(false);
    props.onSubmit ? props.onSubmit(value) : null;
    newCode ? (await projectService.setFileContent(project.id, fileName, newCode)) && notification.success({ message: `Saved` }) : null;
  };

  const yamlCodeString = newCode || content?.data || '';
  const yamlCodeObject = stringToYaml(yamlCodeString);
  const yamlCodeObjectArray: iCodeObjectArray[] = (Array.isArray(yamlCodeObject) ? yamlCodeObject : []).map(
    (yamlObj, indexInArray): iCodeObjectArray => {
      /* Here we will create a custom tabs for each object depending on kind */
      const kind = String(yamlObj.kind).toLocaleLowerCase();
      if (!yamlObj.kind) return { obj: yamlObj, ui: <Alert type="warning" showIcon message="The property '.kind' is not defined" /> };
      if (KindLib[kind] === undefined) return { obj: yamlObj, ui: `For object with kind '${kind}' we do not help ui yet.` };

      /**
       * Function to change any property in the current yaml object
       * @param key - path in the object
       * @param newObj - object to set or undefined if we want to unset
       * @param action - push, splice or undefined (set or unset)
       */
      const onChangeCurrentProperty = (key: string, newObj: any, action?: PatchYamlAction) => {
        yamlCodeObjectArray[indexInArray] = patchYamlCode<iCodeObjectArray>(yamlCodeObjectArray[indexInArray], key, newObj, action);
        const newValue = yamlCodeObjectArray.map(yamlObj => yaml.dump(yamlObj.obj)).join('\n---\n');

        setChanged(true);
        setNewCode(newValue);
        if (props.onChange) props.onChange(newValue);
      };

      const handleDeleteItemClick = () => {
        const newValue = yamlCodeObjectArray
          .filter((_, i) => i !== indexInArray)
          .map(yamlObj => yaml.dump(yamlObj.obj))
          .join('\n---\n');
        setChanged(true);
        setNewCode(newValue || '# You can paste your yaml template here');
        props.onChange ? props.onChange(newValue) : null;
      };

      let helpers = (
        <Space>
          <Button type="dashed" danger onClick={handleDeleteItemClick}>
            <DeleteOutlined />
          </Button>
        </Space>
      );

      // Custom tasbs for each object depending on kind
      const tabsArr = OneKubeItemTabUI(kind, yamlObj, onChangeCurrentProperty);

      return {
        obj: yamlObj,
        helpers: helpers,
        ui: tabsArr.length > 1 ? <Tabs tabPosition={'left'} defaultActiveKey="1" items={tabsArr} /> : tabsArr[0].children,
      };
    },
  );

  const hasContent = yamlCodeObjectArray.length !== 0 || yamlCodeObject.error !== undefined;

  /**
   * Allow to add new yaml item based on `KindLib` object
   * @param kind
   */
  const AddNewYamlItem = async (kind: string) => {
    const res = await getExampleYaml(kind);
    const newCode = `${res.example}\n---\n${yamlCodeString}`;
    setChanged(true);
    setNewCode(newCode);
    res.status && props.onChange ? props.onChange(newCode) : null;
  };

  const menuDropdown = {
    onClick: (e: { key: string }) => AddNewYamlItem(e.key),
    items: Object.keys(KindLib).map((kind: string): any => ({ key: kind, label: KindLib[kind].name })),
  };

  const addYamlDropdown = (
    <Space>
      <Dropdown menu={menuDropdown}>
        <Button style={buttonWidth}>
          <Space direction="horizontal">
            Add new item
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </Space>
  );

  const hasContents = () => (
    <>
      {hasContent && (
        <Space direction="horizontal" key="head">
          <Button type="primary" htmlType="submit" onClick={handleSaveFile} disabled={!isChanged} style={buttonWidth}>
            Save
          </Button>
          {addYamlDropdown}
        </Space>
      )}
    </>
  );

  const resultExtra = (
    <Space direction="horizontal">
      <Button type="primary" style={buttonWidth} onClick={e => AddNewYamlItem(props.mainKindType || 'application')}>
        {props.emptyButtonTitle ? props.emptyButtonTitle : `Create ${props.mainKindType || 'Application'}`}
      </Button>
      {!props.hideOtherObjectTypes && addYamlDropdown}
    </Space>
  );

  const hasContentFalse = () => (
    <>
      {!hasContent && (
        <Col key="left" span={12}>
          <Result
            status={'info'}
            icon={props.emptyIco || <FlagOutlined />}
            title={props.emptyTitle || <EmptyTitle />}
            subTitle={props.emptyDescription || <EmptyDescription />}
            extra={resultExtra}
          />
        </Col>
      )}
    </>
  );

  const hasContentTrue = () => (
    <>
      {hasContent && (
        <Col key="left" span={12}>
          {yamlCodeObject.error && (
            <Alert
              showIcon
              type="error"
              key={`error-alert`}
              message={`${yamlCodeObject.error.name} at line ${yamlCodeObject?.error?.mark?.line} position ${yamlCodeObject?.error?.mark?.column}`}
              description={<pre>{yamlCodeObject.error.message}</pre>}
            />
          )}
          <Collapse size="small" key={`collapse`} defaultActiveKey={yamlCodeObjectArray.map((v, i) => `panel${i}`)}>
            {yamlCodeObjectArray.map((v, i) => (
              <Panel header={`${v.obj.kind} ${v.obj?.metadata?.name}`} key={`panel${i}`} extra={v.helpers}>
                {v.ui}
              </Panel>
            ))}
          </Collapse>
        </Col>
      )}
    </>
  );

  const editorData = () => {
    const handleEditorChange = _.debounce((value: string) => {
      setChanged(true);
      setNewCode(value);
      props.onChange ? props.onChange(value) : null;
    }, 1000);
    return (
      <Col key="editor" span={12}>
        <Space direction="vertical" style={spaceWidth}>
          <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
          <Text />
          <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
            <Editor
              height={isFullscreen ? '100vh' : 'calc(100vh - 215px)'}
              width={`100%`}
              language={`yaml`}
              value={yamlCodeString}
              theme={`GitHub`}
              defaultValue={`# If you have a ready-made yaml template, then paste it here.`}
              onChange={handleEditorChange}
            />
          </FullScreenEditor>
        </Space>
      </Col>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {hasContents()}
      <Row key="body" gutter={24}>
        {hasContentFalse()}
        {hasContentTrue()}
        {editorData()}
      </Row>
    </Space>
  );
};
