import { Alert, Button, Input, Modal, Popconfirm, Skeleton, Space, Timeline } from 'antd';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { iDeployment } from 'shared/deployment';
import { iSpecsRevision } from 'shared/SpecsRevisions';
import { useAuthedMutation, useAuthedMutationWithNotification, useAuthedQuery } from 'utils/qlAuth';
import { bottomMargin } from 'utils/styles';

export const NewRevisionForm = (props: { deployment: iDeployment; onNewRevision: (revision) => void }) => {
  const deployment = props.deployment;
  const [newRevisionName, setNewRevisionName] = useState(``);
  const [newRevisionDescription, setNewRevisionDescription] = useState(``);
  const [showModal, setShowModal] = useState(false);

  const [SpecsRevisionController_createRevision, Revision] = useAuthedMutationWithNotification(gql`
    mutation SpecsRevisionController_createRevision($applicationId: Int!, $name: String, $description: String) {
      SpecsRevisionController_createRevision(applicationId: $applicationId, name: $name, description: $description) {
        id
      }
    }
  `);

  return (
    <>
      <Modal
        title={`Create new revision`}
        open={showModal}
        onOk={async () => {
          const res = await SpecsRevisionController_createRevision({
            variables: { applicationId: deployment.id, name: newRevisionName, description: newRevisionDescription },
          });
          props.onNewRevision(res);
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
      >
        <Space direction="vertical">
          Please provide revision name and description
          <Input
            value={newRevisionName}
            placeholder="Name"
            onChange={e => {
              console.log(e.target.value);
              setNewRevisionName(e.target.value);
            }}
          />
          <Input.TextArea
            placeholder="Description"
            value={newRevisionDescription}
            onChange={e => {
              setNewRevisionDescription(e.target.value);
            }}
          />
        </Space>
      </Modal>
      <Button
        type="primary"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Create new revision
      </Button>
    </>
  );
};
export const SpecsRevisions = (props: { deployment: iDeployment }) => {
  const deployment = props.deployment;
  const [usedRevisionId, setRevisionId] = useState(deployment.lastRevisionId);

  useEffect(() => {
    setRevisionId(deployment.lastRevisionId);
  }, [deployment.lastRevisionId]);

  const specsRevisions = useAuthedQuery(
    gql`
      query SpecsRevisionController_getList($applicationId: Int!) {
        SpecsRevisionController_getList(applicationId: $applicationId) {
          id
          createdAt
          name
          userId
          description
        }
      }
    `,
    { skip: !Number(deployment?.id), variables: { applicationId: Number(deployment?.id) } },
  );

  const [SpecsRevisionController_applyRevision, Result] = useAuthedMutation(gql`
    mutation SpecsRevisionController_applyRevision($applicationId: Int!, $revisionId: Int!) {
      SpecsRevisionController_applyRevision(applicationId: $applicationId, revisionId: $revisionId)
    }
  `);

  if (!specsRevisions.data) {
    return <Skeleton active />;
  }

  const revisions: iSpecsRevision[] = specsRevisions.data?.SpecsRevisionController_getList;

  const newRevisionUI = (
    <NewRevisionForm
      deployment={deployment}
      onNewRevision={revision => {
        specsRevisions.refetch();
      }}
    />
  );

  let TimelineUI = (
    <>
      <p>No any revisions yet</p> {newRevisionUI}
    </>
  );

  if (revisions?.length) {
    const logs = revisions.map((revision: iSpecsRevision) => {
      return {
        color: usedRevisionId === revision.id ? `green` : `grey`,
        children: (
          <Space direction="horizontal">
            Revision {revision.name} created at {new Date(Number(revision.createdAt) * 1000).toLocaleString()}
            <Popconfirm
              title={`Apply revision ${revision.name || `without name`}?`}
              description={
                <Space direction="vertical">
                  <p>{revision.description || `No description`}</p>
                </Space>
              }
              onConfirm={() => {
                setRevisionId(revision.id);
                SpecsRevisionController_applyRevision({ variables: { applicationId: deployment.id, revisionId: revision.id } });
              }}
            >
              <Button disabled={usedRevisionId === revision.id}>{usedRevisionId === revision.id ? `Applied` : `Apply`}</Button>
            </Popconfirm>
          </Space>
        ),
      };
    });

    logs.push({
      color: `blue`,
      children: newRevisionUI,
    });
    TimelineUI = <Timeline mode={'left'} items={logs} reverse={true} />;
  }

  return (
    <Space direction="vertical">
      {TimelineUI}

      <Alert
        showIcon
        type="info"
        key={'info'}
        description={
          <>
            Revision - is a snapshot of your application configuration. You can create a new revision at any time and apply it to your deployment.{' '}
            <br />
            It is useful when you want to test new configuration or rollback to the previous one. You can create a new revision at any time and apply
            it to your application.
          </>
        }
        style={bottomMargin}
      />
    </Space>
  );
};
