import Link from 'antd/lib/typography/Link';
import { useEffect, useRef, useState } from 'react';
import { Form, Button, Dropdown, Space, Menu } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import { CheckOutlined, EditOutlined, PlusOutlined, StarOutlined, StopOutlined } from '@ant-design/icons/lib';
import { ModalForm, ProFormCheckbox, ProFormDigit, ProFormList, ProFormSelect, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table';

interface CustomColumn extends ProColumns<any> {
  render?: (dom: any, entity: any) => JSX.Element;
  dataIndex: string;
  title: string | any;
  valueType?: string | any;
}

interface iProTableApiProps {
  opt: { columns: CustomColumn[]; primaryKey: string; actions?: any[]; canCreate?: boolean; canUpdate?: boolean; canDelete?: boolean };
  Add?: any;
  onAction?: any;
  Update: any;
  getData: any;
  extraButtons?: any[];
}

const { Item } = Menu;
const { Password } = ProFormText;

export const ProTableApi = (props: iProTableApiProps) => {
  const opt = props.opt;
  const Add = props.Add;
  const Update = props.Update;
  const getData = props.getData;
  const onAction = props.onAction;
  const extraButtons = props.extraButtons || [];
  const [currentRow, setCurrentRow] = useState(null);
  const [form] = Form.useForm();
  const [handleOpen, handleModalVisible] = useState<boolean>(false);

  useEffect(() => {
    currentRow ? form?.setFieldsValue(currentRow) : form?.resetFields();
  }, [currentRow]);

  const newBtn = () => {
    const handleOnClick = () => {
      setCurrentRow(null);
      form?.resetFields();
      handleModalVisible(true);
    };
    return !!Add ? (
      <Button type="primary" key="primary" onClick={handleOnClick}>
        <PlusOutlined /> New
      </Button>
    ) : null;
  };

  const actionRef = useRef<ActionType>();

  const handleAction = async (action: any, selectedRows: any[]) => (onAction ? await onAction(action, selectedRows) : null);

  const handleShowEdit = async (entity: any) => {
    await setCurrentRow({ ...entity });
    form?.resetFields();
    await handleModalVisible(true);
    /* await setMyImages([{ key: 'dsf', val: entity.name }]); await setShowDetail(false); await handleUpdateModalVisible(true); */
  };

  const columns: CustomColumn[] = opt.columns.map(({ hideInTable, valueType, dataIndex, title }) => {
    let hidden = false;
    if (hideInTable == true) hidden = true;

    const renderResult = () => (dom: any, entity: any) => {
      if (valueType === 'text' || valueType === 'textarea') return <div> {entity[dataIndex]} </div>;
      if (valueType === 'boolean') {
        return entity[dataIndex] ? <CheckOutlined style={{ color: '#1F6D00' }} /> : <StopOutlined style={{ color: '#8B5B00' }} />;
      }
      if (dataIndex === opt.primaryKey) {
        let menuNotEmpty = opt.canUpdate;
        const menu = () => {
          const canUpdateData = () => {
            const handleOnClick = () => {
              setCurrentRow({ ...entity });
              form?.resetFields();
              handleModalVisible(true);
              /* setMyImages([{ key: 'dsf', val: entity.name }]); setShowDetail(false); handleUpdateModalVisible(true); */
            };
            return opt.canUpdate ? (
              <Item key="edit" icon={<EditOutlined />} onClick={handleOnClick}>
                Edit
              </Item>
            ) : null;
          };

          const actionsData = () => {
            const mappedData = () => (action, index) => {
              const btnProps: any = {};
              const actionData = action;
              const handleOnClick = async () => {
                await handleAction(actionData, [entity]);
                actionRef.current ? actionRef.current.reload() : null;
              };
              menuNotEmpty = true;
              btnProps.icon = action.icon || <StarOutlined />;
              return (
                <Item {...btnProps} key={index} onClick={handleOnClick}>
                  {action.name}
                </Item>
              );
            };
            return opt.actions ? opt.actions.filter(() => true).map(mappedData()) : null;
          };

          return (
            <Menu>
              {canUpdateData()}
              {actionsData()}
            </Menu>
          );
        };

        let dropdownProps: any = {};
        if (menuNotEmpty) dropdownProps.overlay = menu();
        const elem = (
          <Link {...dropdownProps} onClick={async () => await handleShowEdit(entity)} style={{ color: '#000000' }}>
            {dom}
          </Link>
        );

        const handleOnClick = () => {
          setCurrentRow({ ...entity });
          form?.resetFields();
          handleModalVisible(false);
          /* setMyImages([{ key: 'dsf', val: entity.name }]); setShowDetail(true); handleUpdateModalVisible(false); */
        };

        return (
          <Space>
            <Dropdown.Button onClick={handleOnClick} {...dropdownProps}>
              {elem}
            </Dropdown.Button>
          </Space>
        );
      }
      return valueType === 'dateTime' ? <div> {new Date(Number(entity[dataIndex])).toISOString().replace('T', ' ').substring(0, 19)} </div> : null;
    };

    return { title: title, dataIndex: dataIndex, hideInTable: hidden, sorter: true, render: renderResult() };
  });

  const proTableData = () => (
    <ProTable
      headerTitle="Versions"
      rowKey="id"
      search={{ labelWidth: 120 }}
      toolBarRender={() => [...extraButtons, newBtn()]}
      request={getData}
      columns={columns}
      actionRef={actionRef}
      onReset={() => getData({})}
    />
  );

  const modalFormData = () => {
    const handleOnChange = v => {
      handleModalVisible(v);
      setTimeout(() => form?.resetFields(), 0);
    };

    const handleSubmitter = {
      render: ({ submit }) => [
        <Button type="primary" onClick={submit}>
          Save
        </Button>,
      ],
    };

    const handleOnFinish = async value => {
      columns.map(({ dataIndex, normalize, valueType }: any) => {
        const tryCatch = () => {
          try {
            value[dataIndex] = JSON.parse(value[dataIndex]);
          } catch (e) {
            console.warn(`JSON.parse Error (2)`, e);
          }
        };
        normalize && (value[dataIndex] = normalize(value[dataIndex]));
        ['json', 'array', 'map'].includes(valueType) ? tryCatch() : null;
      });
      handleModalVisible(false);
      currentRow && Object.keys(currentRow).length > 0 ? await Update(value) : await Add(value);
      setCurrentRow(null);
      form?.resetFields();
      if (actionRef.current) actionRef.current.reload();
    };

    const columnsData = () => {
      const filteredData = (column: any) => column.new != undefined;

      const mappedData = () => (column: any) => {
        const attrValue = `${currentRow && currentRow[column.dataIndex] ? currentRow[column.dataIndex] : ''}`;
        const attrRules = [{ required: column.new.required, message: `${column.title} is required` }];
        const attr: any = { key: column.dataIndex, label: column.title, name: column.dataIndex, width: 'md', value: attrValue, rules: attrRules };

        if (column.dataIndex === 'images') {
          const arrayImages = currentRow?.['images'] ? Object.keys(currentRow['images']).map(key => ({ key, val: currentRow['images'][key] })) : [];
          const formStyle: any = { position: 'top', creatorButtonText: 'Add image' };
          return (
            <ProFormList name="images" key={currentRow?.['id']} initialValue={arrayImages} creatorButtonProps={formStyle}>
              <Space>
                <ProFormText name={'key'} placeholder="name" />
                <ProFormText name={'val'} placeholder="value" />
              </Space>
            </ProFormList>
          );
        }

        if (column.valueType === 'ProFormTextArea' || column.valueType === 'textarea') return <ProFormTextArea {...attr} />;
        if (['json', 'tags', 'array'].includes(column.valueType)) return <ProFormTextArea {...attr} />;
        if (['ProFormText', 'text', 'link'].includes(column.valueType)) return <ProFormText {...attr} />;
        if (['password'].includes(column.valueType)) return <Password {...attr} />;
        if (column.valueType === 'ProFormDigit' || column.valueType === 'number') return <ProFormDigit {...attr} />;
        if (column.valueType === 'ProFormSelect' || column.valueType === 'select') return <ProFormSelect {...attr} />;
        if (column.valueType === 'ProFormCheckbox' || column.valueType === 'boolean') return <ProFormCheckbox {...attr} />; // attr.options = ['true', 'false'];
        return <ProFormTextArea {...attr} />;
      };

      return opt.columns.filter(filteredData).map(mappedData());
    };

    return (
      <ModalForm
        form={form}
        title="New item"
        width="500px"
        open={handleOpen}
        onChange={handleOnChange}
        submitter={handleSubmitter}
        onFinish={handleOnFinish}
      >
        {columnsData()}
      </ModalForm>
    );
  };

  return (
    <PageContainer>
      {proTableData()}
      {modalFormData()}
    </PageContainer>
  );
};
