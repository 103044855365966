import DeploymentUptimeComponent from 'components/ChartRenderer/DeploymentUptimeComponent';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGqlDeploymentsList } from 'services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { NoApplication } from 'components/SharedComponents/NoPage/NoPages';
import { DeploymentTag } from 'components/Deployments/DeploymentTag';
import { DeploymentErrors } from 'components/Deployments/DeploymentErrors';
import { Row, Col, Button, Image, Skeleton, Tag, Space, Flex, Card, Typography, Input, message, Select, Switch, Result } from 'antd';
import { deploymentCardHeight, imageStyle, cardTextStyle, noWrap, spaceWidth } from 'utils/styles';
import { filterWidth, topMargin, footerStyle, switchMargin } from 'utils/styles';
import { PauseCircleOutlined, LockOutlined, LinkOutlined, UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { MoreOutlined, CaretLeftFilled, RollbackOutlined, CopyTwoTone, FilterTwoTone } from '@ant-design/icons';
import { goToLogin, useUser } from 'utils/common';
import { TipBottom, TipBottomRight, TipLeft, TipRight, TipTop } from 'components/SharedComponents/Tooltip/Tooltip';
import { NewApplicationButton } from './NewApplicationButton';

const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;

export default function Dashboard() {
  const [isCompactView, setIsCompactView] = useState(false);
  const [activeButton, setActiveButton] = useState<'list' | 'card'>('list');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCluster, setSelectedCluster] = useState<string | null>(null);
  const [selectedVersion, setSelectedVersion] = useState<string | null>(null);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const [isSwitch, setIsSwitch] = useState(false);

  const searchInputRef = useRef<any>(null);
  const gqlQuery = useGqlDeploymentsList();
  const user = useUser();
  const data = gqlQuery.data?.DeploymentsController_deployments || [];
  const hasDeployments = data?.length > 0;

  const deploymentData = data.filter((deployment: iDeployment) => {
    const isMatchingName = deployment.name.toLowerCase().includes(searchQuery.toLowerCase()) || deployment.id.toString().includes(searchQuery);
    const isFilterDeployment = !deployment.isReady || deployment.isEnabled;
    const isFilterCluster = selectedCluster ? deployment.RegionModel?.title === selectedCluster : true;
    const isFilterVersion = selectedVersion ? deployment.VersionModel?.name === selectedVersion : true;
    const isFilterProject = selectedProject ? deployment.ProjectModel?.title === selectedProject : true;
    const switches = isMatchingName && isFilterCluster && isFilterVersion && isFilterProject;
    const switched = switches && isFilterDeployment;
    return !isSwitch ? switches : switched;
  });

  const dataLength = deploymentData?.length || 0;

  useEffect(() => {
    searchInputRef.current && (!deploymentData.length || (deploymentData.length && searchInputRef.current)) ? searchInputRef.current.focus() : null;
  }, [deploymentData]);

  const loadingSkeleton = <Skeleton active={true} loading={true} />;

  if (gqlQuery.loading) {
    return loadingSkeleton;
  }
  if (gqlQuery.error) {
    goToLogin();
    return loadingSkeleton;
  }

  const toggleView = (view: 'list' | 'card') => {
    setIsCompactView(view === 'card');
    setActiveButton(view);
  };

  const handleCopyClick = (event, domain) => {
    navigator.clipboard.writeText(domain).then(() => message.success('Link copied successfully...'));
    event.stopPropagation();
    event.preventDefault();
  };

  const filterProject = Array.from(
    new Set(deploymentData.map((deployment: { ProjectModel: { title: any } }) => deployment.ProjectModel?.title)),
  ).filter(Boolean);

  const filterCluster = Array.from(
    new Set(deploymentData.map((deployment: { RegionModel: { title: any } }) => deployment.RegionModel?.title)),
  ).filter(Boolean);

  const filterVersion = Array.from(
    new Set(deploymentData.map((deployment: { VersionModel: { name: any } }) => deployment.VersionModel?.name)),
  ).filter(Boolean);

  const resetOption = (
    <Flex justify="flex-end">
      <Text strong type="warning">
        Reset <RollbackOutlined />
      </Text>
    </Flex>
  );

  const searchBar = () => (
    <Search
      ref={searchInputRef}
      placeholder="Search using application name or ID"
      data-qa="search-input"
      enterButton
      allowClear
      value={searchQuery}
      onChange={e => setSearchQuery(e.target.value)}
    />
  );

  const Filters = ({ placeholder, filterOptions, selectedValue, setSelectedValue, resetOption }) => (
    <Select showSearch optionFilterProp="children" value={selectedValue} onChange={setSelectedValue} style={filterWidth}>
      <Option value={null}> {selectedValue ? resetOption : <Text style={footerStyle}> {placeholder} </Text>} </Option>
      {filterOptions.map(option => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );

  const projectsFilter = () => (
    <Filters
      placeholder="Projects"
      filterOptions={filterProject}
      selectedValue={selectedProject}
      setSelectedValue={setSelectedProject}
      resetOption={resetOption}
    />
  );

  const clustersFilter = () => (
    <Filters
      placeholder="Clusters"
      filterOptions={filterCluster}
      selectedValue={selectedCluster}
      setSelectedValue={setSelectedCluster}
      resetOption={resetOption}
    />
  );

  const versionsFilter = () => (
    <Filters
      placeholder="Versions"
      filterOptions={filterVersion}
      selectedValue={selectedVersion}
      setSelectedValue={setSelectedVersion}
      resetOption={resetOption}
    />
  );

  const filterReset = () => {
    setSelectedProject(null);
    setSelectedCluster(null);
    setSelectedVersion(null);
  };

  const resetFilters = () => {
    const filtersApplied = selectedProject || selectedCluster || selectedVersion;
    return (
      filtersApplied && (
        <TipRight tip="Reset all filters">
          <FilterTwoTone twoToneColor="#00A58E" onClick={filterReset} />
        </TipRight>
      )
    );
  };

  const searchSwitch = () => (
    <Flex justify={'flex-end'}>
      <TipLeft tip={isSwitch ? 'Click to view All Applications' : 'Click to view Running Applications'}>
        <Switch
          checkedChildren="Running"
          unCheckedChildren="All"
          defaultChecked={isSwitch}
          onChange={checked => setIsSwitch(checked)}
          style={switchMargin}
        />
      </TipLeft>
    </Flex>
  );

  const searchButtons = () => (
    <Flex justify={'flex-end'}>
      <Space direction="horizontal">
        <TipTop tip="List View">
          <Button icon={<UnorderedListOutlined />} onClick={() => toggleView('list')} type={activeButton === 'list' ? 'primary' : 'default'} />
        </TipTop>
        <TipTop tip="Grid View">
          <Button icon={<AppstoreOutlined />} onClick={() => toggleView('card')} type={activeButton === 'card' ? 'primary' : 'default'} />
        </TipTop>
        <NewApplicationButton />
      </Space>
    </Flex>
  );

  const searchViews = () => (
    <Space direction="vertical" style={spaceWidth}>
      <Row>
        <Col span={10}> {searchBar()} </Col>
        <Col span={14}> {searchButtons()} </Col>
      </Row>
      <Row />
      <Row>
        <Col span={20}>
          <Space direction="horizontal">
            {projectsFilter()} {clustersFilter()} {versionsFilter()} {resetFilters()}
          </Space>
        </Col>
        <Col span={4}> {searchSwitch()} </Col>
      </Row>
    </Space>
  );

  const noData = () => {
    const noResults = (
      <Button type="primary" onClick={() => setSearchQuery('')} icon={<CaretLeftFilled />}>
        Back to Applications
      </Button>
    );
    const noResultsSubTitle = 'This Application does not exist... Please search for appropriate Application';
    return (
      <>
        {searchViews()}
        <Result status="404" subTitle={noResultsSubTitle} extra={noResults} style={topMargin} />
      </>
    );
  };

  const deploymentCardData = () => (
    <Space direction="vertical" style={spaceWidth}>
      {searchViews()}
      <Text />
      <Row gutter={[16, 16]}>
        {deploymentData.map((deployment: iDeployment) => {
          const cloudProvider = deployment.RegionModel ? deployment.RegionModel.cloudProvider : 'on-premise';
          const isFilterCluster = selectedCluster ? deployment.RegionModel?.title === selectedCluster : true;
          const isFilterVersion = selectedVersion ? deployment.VersionModel?.name === selectedVersion : true;
          const isTagTrunc = deployment.ProjectModel?.title;
          const isVerTrunc = deployment.VersionModel;
          if (!isFilterCluster || !isFilterVersion) return null;

          const deploymentModel = deployment.ProjectModel ? (
            <Link to={`/project/${deployment.ProjectModel?.id}`}>
              <Tag icon={<LinkOutlined />} color="default">
                {isCompactView || activeButton === 'card' ? (isTagTrunc.length > 8 ? `${isTagTrunc.slice(0, 8)}...` : isTagTrunc) : isTagTrunc}
              </Tag>
            </Link>
          ) : null;

          const freezedStatus = deployment.freezed ? (
            <Tag color="cyan">
              Freezed <LockOutlined />
            </Tag>
          ) : null;

          const addonStatus =
            deployment.dpCreationType === `addon` ? (
              <Tag color="cyan" title="This application was created as an add on to the cluster.">
                Add on
              </Tag>
            ) : null;

          const deploymentUptime = deployment.isEnabled ? <DeploymentUptimeComponent deploymentId={deployment.id} minutes={60} /> : null;

          const deploymentStatus =
            deployment.isReady && !deployment.isEnabled ? (
              <Link to={`/app/${deployment.id}/settings/delete`}>
                <Tag color="orange" icon={<PauseCircleOutlined />}>
                  On Pause
                </Tag>
              </Link>
            ) : null;

          const deploymentError = <DeploymentErrors deployment={deployment} />;

          var cardTruncate = -1;
          {
            isCompactView && (
              <>
                {cardTruncate++}
                {deployment.ProjectModel && cardTruncate++}
                {deployment.freezed && cardTruncate++}
                {deployment.isEnabled && cardTruncate++}
                {deployment.isReady && !deployment.isEnabled && cardTruncate++}
              </>
            );
          }

          const deploymentModelCompact = <TipBottom tip={(isCompactView && [isTagTrunc]) || ''}> {deploymentModel} </TipBottom>;

          const deploymentTags = () => {
            const commonTags = [freezedStatus, addonStatus, deploymentUptime, deploymentStatus, deploymentError];
            const listTags = [deploymentModel, commonTags];
            const gridTags = [deploymentModelCompact, commonTags];
            const tagsTooltipTitle = (isCompactView && commonTags) || '';
            return (
              <>
                {cardTruncate === -1 ? listTags : (isCompactView && gridTags.find(variable => variable !== null)) || ''}
                {isCompactView && cardTruncate > 1 ? (
                  <TipBottom tip={tagsTooltipTitle}>
                    <Button type="text" size="small" icon={<MoreOutlined rotate={90} />} />
                  </TipBottom>
                ) : (
                  ''
                )}
              </>
            );
          };

          const deploymentTitle = deployment.RegionModel && deployment.RegionModel.id && (
            <Link to={`/clusters/${deployment.RegionModel.id}/status/overview`}>
              <TipBottom tip="Click here for Cluster">
                <Tag> {deployment.RegionModel.title} </Tag>
              </TipBottom>
            </Link>
          );

          const versionData = isVerTrunc ? `${isVerTrunc.channel} ${isVerTrunc.name}` : '';

          const versionDataTruncate = () =>
            isVerTrunc
              ? `${isVerTrunc.channel.slice(0, 15)} ${isVerTrunc.channel.length > 15 ? '...' : ''} ${isVerTrunc.name.slice(0, 15)}
              ${isVerTrunc.name.length > 15 ? '...' : ''}`
              : '';

          const versionDataTruncated = () => isVerTrunc && <TipBottomRight tip={versionData}> {versionDataTruncate()} </TipBottomRight>;

          const versionTags = isVerTrunc && (
            <Tag color="#4E5B59AA">
              <Text strong style={cardTextStyle}>
                {isCompactView ? (isVerTrunc.channel.length > 10 || isVerTrunc.name.length > 10 ? versionDataTruncated() : versionData) : versionData}
              </Text>
            </Tag>
          );

          const deploymentImages =
            cloudProvider === 'aws'
              ? 'branding/aws.png'
              : cloudProvider === 'azure'
              ? 'branding/azure.svg'
              : cloudProvider === 'gcp'
              ? 'branding/gsp.svg'
              : 'branding/kubernetes.svg';

          const cardExtra = (
            <>
              {deploymentTitle}
              <Tag color="default"> ID: {deployment.id} </Tag>
              <Image src={deploymentImages} alt={cloudProvider} preview={false} style={imageStyle} />
            </>
          );

          const deploymentsContent = () => (
            <Col span={isCompactView ? 8 : 24} key={deployment.id}>
              <Link to={!deployment.isReady ? `/app/${deployment.id}/setting/details` : `/app/${deployment.id}/status/overview`}>
                <Card
                  size="small"
                  type="inner"
                  title={deploymentTags()}
                  extra={cardExtra}
                  data-cy={deployment.id}
                  bordered={false}
                  style={isCompactView ? deploymentCardHeight : ''}
                >
                  <Row>
                    <Col span={12}>
                      <Space direction="vertical">
                        <Text strong> {deployment.name} </Text>
                        <Space direction="horizontal">
                          <TipRight tip={`Copy link for ${deployment.domain} here`}>
                            <CopyTwoTone twoToneColor="#00A58E" onClick={event => handleCopyClick(event, deployment.domain.toString())} />
                          </TipRight>
                          <Text style={noWrap}> {deployment.domain} </Text>
                        </Space>
                        <DeploymentTag deployment={deployment} />
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Flex justify={'flex-end'}> {versionTags} </Flex>
                    </Col>
                  </Row>
                </Card>
              </Link>
              <Text />
            </Col>
          );
          return deploymentsContent();
        })}
      </Row>
      <Text />
    </Space>
  );

  if (!hasDeployments) return <NoApplication />;
  if (dataLength) return deploymentCardData();

  return dataLength > 0 ? <NoApplication /> : noData();
}
