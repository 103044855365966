import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { Skeleton, Space } from 'antd';
import { useGqlDeploymentsList } from 'services/deployment.service';
import { NewProjectForm } from 'components/Projects/NewProjectForm';
import { BigOption, BigOptions, Center, InfoApplication } from 'components/SharedComponents/BigOptions/BigOptions';

export function SelectDeployWay() {
  const user = useUser();
  const gqlQuery = useGqlDeploymentsList();
  const data = gqlQuery.data?.DeploymentsController_deployments || [];
  const hasDeployments = data?.length > 0;
  const create = UserPermissions.DeploymentCreate;

  const createCard = () => {
    const copy = 'Copy existing application';
    const existing = 'Deploy from public template';
    const scratch = 'Deploy from scratch';
    const image = 'branding/newCluster.svg';

    const copyCard = hasDeployments
      ? { name: copy, tip: copy, img: image, path: '/new', secondary: 'Create a new application by copying an existing one' }
      : null;

    const existingCard = {
      name: existing,
      tip: existing,
      img: image,
      path: '/new-from-template',
      secondary: 'Choose from a list of public preconfigured templates',
    };

    const newCard = {
      customUI: (
        <NewProjectForm openBtnUI={<BigOption name={scratch} tip={scratch} secondary="Create a new template and deploy your application" />} />
      ),
    };

    return <BigOptions options={[copyCard, existingCard, newCard]} />;
  };

  if (gqlQuery?.loading) return <Skeleton active={true} loading={true} />;
  if (!userHasPermission(user, create)) return <UserNoPermissions permission={create} />;
  return (
    <Center>
      <Space direction="vertical">
        <InfoApplication />
        {createCard()}
      </Space>
    </Center>
  );
}
