import DeploymentEnvVariablesForm from '../EnvironmentVariable/EnvironmentVariable';
import { Key, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { filterVersions } from '../General/DeploymentUpdateVersion';
import { iDeployment, iDeploymentServicesConfig } from 'shared/deployment';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { ServicesRequests } from '../General/ServicesRequests';
import { layout, updateDeployment } from '../common';
import { useGqlAllServicesConfigurationUI, useGqlDeploymentById } from 'services/deployment.service';
import { versionsService } from 'services/versions.service';
import { DeploymentOneServiceSettings } from '../General/DeploymentOneServiceSettings';
import { Button, Form, Input, Modal, Select, Skeleton, Space, Typography } from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { buttonBorder, buttonWidth, spaceWidth } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { DeploymentStep3 } from 'components/SharedComponents/CreateSteps/CreateSteps';

const { Text } = Typography;
const { Item } = Form;
const { Option } = Select;

/**
 * Render a modal with a form to choose enabled services
 * @param props
 * @returns
 */

const NewDeploymentServicesSettingsForm = (props: { deploymentId: number; projectId: number }) => {
  const dpQuery = useGqlDeploymentById(props.deploymentId);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [services, error, loading] = useApiQuery(() => projectService.getProjectServiceList(props.projectId));

  const handleOk = () => setIsModalOpen(false);

  const servicesArr = services || [];
  const enabledServices = () => {
    const handleServiceClick = () => {
      dpQuery.refetch();
      setIsModalOpen(true);
    };

    const handleOnFinishForm = async values => {
      await updateDeployment(deployment.id, values);
      handleOk();
    };

    const handleOkClick = () => handleOk();

    const footerForm = () => (
      <Form initialValues={{}} onFinish={handleOnFinishForm} {...layout}>
        {servicesArr.map((key: { name: Key; UIshowRequestsConfig: any }) => (
          <ServicesRequests key={key.name} deployment={deployment} value={key.name} UIshowRequestsConfig={key.UIshowRequestsConfig} />
        ))}
        <Item>
          <Space>
            <Button key="back" onClick={handleOkClick} style={buttonWidth}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" style={buttonWidth}>
              Save
            </Button>
          </Space>
        </Item>
      </Form>
    );

    const modalTitle = 'Choose Enabled Services Here';
    const modalFooter = loading || dpQuery.error ? <Skeleton active={true} loading={true} /> : footerForm();

    return (
      <>
        <Button onClick={handleServiceClick}>
          <EditOutlined /> Enabled services
        </Button>
        <Modal centered width={'50%'} title={modalTitle} footer={modalFooter} open={isModalOpen} onOk={handleOk} onCancel={handleOk} />
      </>
    );
  };

  return enabledServices();
};

/**
 * Render a modal with a form to Environment Variables configuration
 * @param props
 * @returns
 */

const NewDeploymentEnvVarsForm = (props: { deploymentId: number; projectId: number; title: string }) => {
  const dpQuery = useGqlDeploymentById(props.deploymentId);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => setIsModalOpen(false);

  const handleOnClick = () => {
    dpQuery.refetch();
    setIsModalOpen(true);
  };

  return (
    <>
      <Button onClick={handleOnClick}>
        <EditOutlined /> {props.title}
      </Button>
      <Modal footer={null} centered width={'50%'} title={props.title} open={isModalOpen} onOk={handleOk} onCancel={handleOk}>
        {dpQuery.loading || dpQuery.error ? (
          <Skeleton active={true} loading={true} />
        ) : (
          <DeploymentEnvVariablesForm deployment={deployment} onSave={handleOk} />
        )}
      </Modal>
    </>
  );
};

const NewDeploymentVersionForm = (props: { deployment: iDeployment }) => {
  const deployment: iDeployment = props.deployment;
  const [filter, setFilter] = useState('');
  const [versions, versionsError, versionsLoading] = useApiQuery(() => versionsService.publicVersions(deployment.projectId));

  if (versionsError) {
    return (
      <Skeleton active={true} loading={true}>
        versionsError: {JSON.stringify(versionsError)}
      </Skeleton>
    );
  }

  if (versionsLoading) return <Skeleton active={true} loading={true} />;
  if (!versions.length) return null;

  return (
    <>
      <Item label={<Text strong> Choose Release Channel </Text>}>
        <Input
          prefix={<SearchOutlined className="site-form-item-icon" />}
          placeholder="Filter by Versions"
          onChange={e => setFilter(e.target.value)}
          value={filter}
        />
      </Item>
      <Item name="version" label="Choose Release Version" rules={[{ required: true, message: 'Please Select Version' }]}>
        <Select placeholder="Choose Release Version Here" value={deployment.version} defaultValue={deployment.version}>
          {[...versions]
            .sort((a, b) => b.id - a.id)
            .filter(el => filterVersions(el, filter))
            .map(v => (
              <Option value={v.id} key={v.id}>
                #{v.id}
                <Text italic style={{ color: '#B5B5B5' }}>
                  ({v.channel})
                </Text>
                {v.build ? ` v${v.build}` : 'Last'} - {v.name}
                <Text italic style={{ color: '#57A2FF' }}>
                  - {v.title}
                </Text>
              </Option>
            ))}
        </Select>
      </Item>
    </>
  );
};

const NewDeploymentServicesConfigurationForm = (props: { deployment: iDeployment }) => {
  const dpQuery = useGqlDeploymentById(props.deployment.id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const serviceQuery = useGqlAllServicesConfigurationUI(props.deployment.id);
  const servicesConfig: iDeploymentServicesConfig[] = serviceQuery.data?.DeploymentsController_AllServicesConfigurationUI || [];

  if (serviceQuery.loading || serviceQuery.error) return <Skeleton active={true} loading={true} />;
  if (!servicesConfig.find(elem => elem.hasUIconfig)) return null;

  return (
    <Item label="Choose Services Configs">
      <Space>
        {servicesConfig
          .filter(elem => elem.hasUIconfig)
          .map(elem => {
            const handleConfigClick = async () => {
              await dpQuery.refetch();
              const modalContent =
                dpQuery.loading || dpQuery.error ? (
                  <Skeleton active={true} loading={true} />
                ) : (
                  <DeploymentOneServiceSettings
                    deployment={deployment}
                    serviceName={elem.serviceName}
                    schema={elem}
                    onSave={() => instance.destroy()}
                    inPopup={true}
                  />
                );
              let instance = Modal.info({ title: elem.serviceName, width: '50%', icon: <EditOutlined />, content: modalContent, footer: null });
            };
            return (
              <Button key={elem.serviceName} onClick={handleConfigClick}>
                <EditOutlined /> {elem.serviceName}
              </Button>
            );
          })}
      </Space>
    </Item>
  );
};

export const DeploymentCustomisePage = (props: { deployment: iDeployment }) => {
  const { deployment } = props;
  const history = useHistory();
  const formData = () => {
    const handleOnFinishDomain = async values => {
      await updateDeployment(deployment.id, values);
      history.push(`/app/${props.deployment.id}/setting/deploy`);
    };
    return (
      <Form onFinish={handleOnFinishDomain} {...layout}>
        <Item name="domain" label={<Text strong> Domain of the Application </Text>} initialValue={deployment.domain}>
          <Input placeholder="Enter application domain" />
        </Item>
        {deployment.ProjectModel?.showVerionsTab !== false ? <NewDeploymentVersionForm deployment={deployment} /> : null}
        <Item label={<Text strong> Choose Enabled Services </Text>}>
          <NewDeploymentServicesSettingsForm deploymentId={deployment.id} projectId={deployment.projectId} />
        </Item>
        {deployment.ProjectModel?.showEnvVarsTab !== false ? (
          <Item label={<Text strong> Choose Environment Variables </Text>}>
            <NewDeploymentEnvVarsForm deploymentId={deployment.id} projectId={deployment.projectId} title="Environment Variables" />
          </Item>
        ) : null}
        <NewDeploymentServicesConfigurationForm deployment={deployment} />
        <Item>
          <BottomButtons>
            <Button type="primary" htmlType="submit" style={buttonBorder}>
              Save and continue
            </Button>
          </BottomButtons>
        </Item>
      </Form>
    );
  };

  const createDeploymentStep3 = () => (
    <Space direction="vertical" style={spaceWidth}>
      <DeploymentStep3 />
      <Space>
        <Text />
      </Space>
      {formData()}
    </Space>
  );

  return createDeploymentStep3();
};
